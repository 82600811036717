<template>
  <template v-if="postType === PostTypeActionEnum.Comment || postType === PostTypeActionEnum.TaskComment">
    <div>
      <div v-if="isSendLoading" class="loader">
        <icons-provider :icon-props="{ width: '22', height: '22' }" :name="AppIconsEnum.CircleAnim" />
      </div>

      <div class="files-list">
        <template v-if="files.length > 0">
          <close-block-wrapper
            v-for="item in files"
            :key="item.key"
            class="file-wrapper"
            @on-action="onDeleteFile(item)"
          >
            <file-icon-preview :payload="item.payload" />
          </close-block-wrapper>
        </template>

        <template v-if="attachedFiles.length > 0">
          <close-block-wrapper
            v-for="item in attachedFiles"
            :key="item.key"
            class="file-wrapper"
            @on-action="onDeleteFile(item)"
          >
            <media-image v-if="!!item.image" :name="item.name" :internal="item.key" :image="item.image" />

            <media-image
              v-else-if="item.videoPreview !== null"
              :name="item.name"
              :internal="item.key"
              :image="item.videoPreview"
            />
            <file-info v-else :size="item.size" :name="item.name" />
          </close-block-wrapper>
        </template>

        <template v-if="tempFiles.length > 0">
          <close-block-wrapper
            v-for="item in tempFiles"
            :key="item.key"
            class="file-wrapper"
            @on-action="onDeleteFile(item)"
          >
            <media-image
              v-if="isFileGuard(item) && !!item.image"
              :name="item.name"
              :internal="item.key"
              :image="item.image"
            />

            <media-image
              v-else-if="isFileGuard(item) && !!item.videoPreview"
              :name="item.name"
              :internal="item.key"
              :image="item.videoPreview"
            />
            <file-info
              v-else
              :type="isFileGuard(item) ? item.type : item.contentType"
              :size="isFileGuard(item) ? item.size : item.contentLength"
              :name="item.name"
            />
          </close-block-wrapper>
        </template>

        <template v-if="attachedWikis.length > 0">
          <close-block-wrapper
            v-for="item in attachedWikis"
            :key="item.id"
            class="file-wrapper"
            @on-action="onDeleteWiki(item)"
          >
            <file-info :name="item.name" type="wiki" />
          </close-block-wrapper>
        </template>
      </div>

      <ion-toolbar mode="md" :class="[{ 'task-toolbar': postType === PostTypeActionEnum.TaskComment }]" @click.stop>
        <div class="toolbar-container">
          <ion-item
            v-tooltip.bottom="{
              content: $t('files.addAttachment'),
              theme: 'info-tooltip',
              disabled: isAnyMobile,
            }"
            class="attach-btn"
            :disabled="isFilesLoading"
            type="button"
            lines="none"
            @click="attachAction"
          >
            <icons-provider
              :icon-props="{
                width: '22',
                height: '22',
                fill: 'var(--ion-color-medium)',
              }"
              :name="AppIconsEnum.Paperclip"
            />
          </ion-item>
          <ion-textarea
            ref="textAreaRef"
            v-model="text"
            mode="ios"
            :rows="1"
            :auto-grow="true"
            :placeholder="$t('feed.comments.text')"
            @keydown.enter="keyUp"
            @ion-focus="commentFocus()"
            @ion-blur="commentBlur()"
            @ion-input="onChange"
          />

          <ion-buttons mode="md">
            <feed-send-button
              :is-loading="isSendLoading"
              :disabled="isFilesLoading || nothingToPush"
              :post-type="postType"
              :is-small="postType === PostTypeActionEnum.TaskComment"
              @on-send-click="actionSend"
            />
          </ion-buttons>
        </div>
      </ion-toolbar>

      <app-mentions-chips
        v-if="postType !== PostTypeActionEnum.TaskComment && mentionsIsActive"
        :message="text"
        :caret="caret"
        :mention-mode="MentionModeEnum.ByCaret"
        :choose-users-is-active="true"
        :choose-topics-is-active="true"
        :choose-groups-is-active="false"
        :choose-email-is-active="false"
        @on-choose-user="onAppend"
        @on-choose-topic="onAppend"
        @on-choose-group="onAppend"
      />
    </div>
  </template>

  <template
    v-if="
      postType === PostTypeActionEnum.Text ||
      postType === PostTypeActionEnum.Announcement ||
      postType === PostTypeActionEnum.Poll ||
      postType === PostTypeActionEnum.Idea ||
      postType === PostTypeActionEnum.Badge ||
      postType === PostTypeActionEnum.Task
    "
  >
    <feed-post-photo
      v-if="postType === PostTypeActionEnum.Announcement"
      :announcement-current-photo="coverImage"
      :cover-delete-flag="coverDeleteFlag"
      @on-photo-selected="getSelectedPhotoId"
      @on-photo-uploaded="getSelectedPhotoObj"
      @on-photo-loading="photoLoading"
    />

    <div v-if="somethingIsAttached" class="files-list">
      <template v-if="files.length > 0">
        <close-block-wrapper v-for="item in files" :key="item.key" class="file-wrapper" @on-action="onDeleteFile(item)">
          <file-icon-preview :payload="item.payload" />
        </close-block-wrapper>
      </template>

      <template v-if="attachedFiles.length > 0">
        <close-block-wrapper
          v-for="item in attachedFiles"
          :key="item.key"
          class="file-wrapper"
          @on-action="onDeleteFile(item)"
        >
          <media-image v-if="!!item.image" :name="item.name" :internal="item.key" :image="item.image" />

          <media-image
            v-else-if="item.videoPreview !== null"
            :name="item.name"
            :internal="item.key"
            :image="item.videoPreview"
          />
          <file-info v-else :size="item.size" :name="item.name" />
        </close-block-wrapper>
      </template>

      <template v-if="tempFiles.length > 0">
        <close-block-wrapper
          v-for="item in tempFiles"
          :key="item.key"
          class="file-wrapper"
          @on-action="onDeleteFile(item)"
        >
          <media-image
            v-if="isFileGuard(item) && !!item.image"
            :name="item.name"
            :internal="item.key"
            :image="item.image"
          />

          <media-image
            v-else-if="isFileGuard(item) && !!item.videoPreview"
            :name="item.name"
            :internal="item.key"
            :image="item.videoPreview"
          />
          <file-info
            v-else
            :type="isFileGuard(item) ? item.type : item.contentType"
            :size="isFileGuard(item) ? item.size : item.contentLength"
            :name="item.name"
          />
        </close-block-wrapper>
      </template>

      <template v-if="attachedWikis.length > 0">
        <close-block-wrapper
          v-for="item in attachedWikis"
          :key="item.id"
          class="file-wrapper"
          @on-action="onDeleteWiki(item)"
        >
          <file-info :name="item.name" type="wiki" />
        </close-block-wrapper>
      </template>
    </div>

    <div class="post_create-controls">
      <div v-if="postType !== PostTypeActionEnum.Badge" class="attach">
        <ion-button
          v-tooltip.bottom="{
            content: $t('files.addAttachment'),
            theme: 'info-tooltip',
            disabled: isAnyMobile,
          }"
          @click="attachAction"
        >
          <icons-provider
            slot="icon-only"
            :icon-props="{
              width: '22',
              height: '22',
              fill: 'var(--ion-color-medium)',
            }"
            :name="AppIconsEnum.Paperclip"
          />
        </ion-button>
      </div>
      <div class="send">
        <app-group-select-button
          v-if="postType !== PostTypeActionEnum.Badge"
          class="group-select-button"
          :post-type="postType"
          :note="$t('files.publisherNote')"
          :user-id="onBehalfUser?.id"
          :access-to-actions="accessToGroupSelectActions"
          :auto-select-mode="groupAutoSelectMode"
          @on-select-change="selectChange"
        />

        <app-user-select-button
          v-if="
            isPostOnBehalfAvailable &&
            (postType === PostTypeActionEnum.Text ||
              postType === PostTypeActionEnum.Announcement ||
              postType === PostTypeActionEnum.Poll ||
              postType === PostTypeActionEnum.Idea)
          "
          class="group-select-button"
          :title="t('feed.onBehalf')"
          :group-id="selectedGroupToPost?.id"
          @on-select-change="selectOnBehalfUser"
        />

        <task-management-project-switch-button
          v-if="postType === PostTypeActionEnum.Task"
          :with-routing="false"
          is-post-creation
          :selected-group-id="selectedGroupToPost?.id"
        />

        <app-schedule-post-button
          v-if="
            postType === PostTypeActionEnum.Text ||
            postType === PostTypeActionEnum.Announcement ||
            postType === PostTypeActionEnum.Poll ||
            postType === PostTypeActionEnum.Idea ||
            showScheduleButton
          "
          class="schedule-post-button"
          :post-type="postType"
          @on-select-date="selectDate"
        />

        <div v-if="postType === PostTypeActionEnum.Task ? !!taskData?.projectId : true" class="preview-post-button">
          <ion-button fill="outline" @click="actionPreview">
            <span class="wrap-text">{{ t('preview') }}</span>
          </ion-button>
        </div>

        <div class="submit">
          <feed-send-button
            :is-loading="isSendLoading"
            :disabled="disabledToSend"
            :post-type="postType"
            @on-send-click="actionSend"
          />
        </div>
      </div>

      <!-- NOTE: Badge post type doesn't support upload files -->
      <!-- NOTE: On mobile devices, the drag zone is not displayed -->
      <!-- NOTE: The drag zone is displayed when the drag is active or the drop is loading -->
      <app-drag-zone
        v-if="isDragZoneDisplayed"
        class="drag"
        :file-types="UploadFileTypes.ManyDifferentFiles"
        is-publisher
        @files="droppedFiles"
        @all-files-is-loaded="dropIsLoading"
      />
    </div>
  </template>

  <template v-if="postType === PostTypeActionEnum.Event || postType === PostTypeActionEnum.CalendarEvent">
    <div class="post_create-controls">
      <div />
      <div class="send">
        <app-group-select-button
          class="group-select-button"
          :post-type="postType"
          :note="$t('files.publisherNote')"
          :user-id="onBehalfUser?.id"
          :access-to-actions="accessToGroupSelectActions"
          :auto-select-mode="groupAutoSelectMode"
          @on-select-change="selectChange"
        />

        <app-user-select-button
          v-if="isPostOnBehalfAvailable"
          class="group-select-button"
          :title="t('feed.onBehalf')"
          :group-id="selectedGroupToPost?.id"
          @on-select-change="selectOnBehalfUser"
        />

        <app-schedule-post-button
          v-if="showScheduleButton"
          class="schedule-post-button"
          :post-type="postType"
          @on-select-date="selectDate"
        />
        <div class="preview-post-button">
          <ion-button fill="outline" @click="actionPreview">
            <span class="wrap-text">{{ t('preview') }}</span>
          </ion-button>
        </div>
        <div class="submit">
          <feed-send-button
            :is-loading="isSendLoading"
            :disabled="disabledToSend"
            :post-type="postType"
            @on-send-click="actionSend"
          />
        </div>
      </div>
    </div>
  </template>

  <template
    v-if="
      postType === PostTypeActionEnum.EditText ||
      postType === PostTypeActionEnum.EditEvent ||
      postType === PostTypeActionEnum.EditIdea ||
      postType === PostTypeActionEnum.EditPoll ||
      postType === PostTypeActionEnum.EditAnnouncement
    "
  >
    <feed-post-photo
      v-if="postType === PostTypeActionEnum.EditAnnouncement"
      :announcement-current-photo="coverImage"
      :cover-delete-flag="coverDeleteFlag"
      @on-photo-selected="getSelectedPhotoId"
      @on-photo-loading="photoLoading"
    />
    <div v-if="postType !== PostTypeActionEnum.EditEvent" class="files-list">
      <template v-if="files.length > 0">
        <close-block-wrapper v-for="item in files" :key="item.key" class="file-wrapper" @on-action="onDeleteFile(item)">
          <file-icon-preview :payload="item.payload as string" />
        </close-block-wrapper>
      </template>

      <template v-if="attachedFiles.length > 0">
        <close-block-wrapper
          v-for="item in attachedFiles"
          :key="item.key"
          class="file-wrapper"
          @on-action="onDeleteFile(item)"
        >
          <!-- TODO add :lqip="item.image?.lqip" -->
          <media-image v-if="!!item.image" :name="item.name" :internal="item.key" :image="item.image" />
          <!-- TODO add :lqip="item.image?.lqip" -->
          <media-image
            v-else-if="item.videoPreview !== null"
            :name="item.name"
            :internal="item.key"
            :image="item.videoPreview"
          />
          <file-info v-else :size="item.size" :name="item.name" />
        </close-block-wrapper>
      </template>

      <template v-if="tempFiles.length > 0">
        <close-block-wrapper
          v-for="item in tempFiles"
          :key="item.key"
          class="file-wrapper"
          @on-action="onDeleteFile(item)"
        >
          <!-- TODO add :lqip="item.image?.lqip" -->
          <media-image
            v-if="isFileGuard(item) && !!item.image"
            :name="item.name"
            :internal="item.key"
            :image="item.image"
          />
          <!-- TODO add :lqip="item.image?.lqip" -->
          <media-image
            v-else-if="isFileGuard(item) && !!item.videoPreview"
            :name="item.name"
            :internal="item.key"
            :image="item.videoPreview"
          />
          <file-info
            v-else
            :type="isFileGuard(item) ? item.type : item.contentType"
            :size="isFileGuard(item) ? item.size : item.contentLength"
            :name="item.name"
          />
        </close-block-wrapper>
      </template>

      <template v-if="attachedWikis.length > 0">
        <close-block-wrapper
          v-for="item in attachedWikis"
          :key="item.id"
          class="file-wrapper"
          @on-action="onDeleteWiki(item)"
        >
          <file-info :name="item.name" type="wiki" />
        </close-block-wrapper>
      </template>
    </div>

    <div class="post_create-controls">
      <div v-if="postType !== PostTypeActionEnum.EditEvent" class="attach">
        <ion-button
          v-tooltip.bottom="{
            content: $t('files.addAttachment'),
            theme: 'info-tooltip',
            disabled: isAnyMobile,
          }"
          @click="attachAction"
        >
          <icons-provider
            slot="icon-only"
            :icon-props="{
              width: '22',
              height: '22',
              fill: 'var(--ion-color-medium)',
            }"
            :name="AppIconsEnum.Paperclip"
          />
        </ion-button>
      </div>
      <ion-button
        v-if="postType === PostTypeActionEnum.EditAnnouncement"
        class="delete"
        mode="md"
        fill="clear"
        :disabled="!coverImage"
        @click="deleteCoverImage"
      >
        {{ t('coverImage.coverMenu.delete') }}
      </ion-button>
      <div class="send">
        <app-group-select-button
          v-if="isCurrentPostPlanned"
          class="group-select-button"
          :post-type="postType"
          :note="$t('files.publisherNote')"
          :group-data="postData.group"
          :user-id="onBehalfUser?.id"
          :access-to-actions="accessToGroupSelectActions"
          :auto-select-mode="groupAutoSelectMode"
          @on-select-change="selectChange"
        />

        <!--for cases of editing show feed-on-behalf-selector only for planned posts-->
        <app-user-select-button
          v-if="isPostOnBehalfAvailable && isCurrentPostPlanned"
          class="group-select-button"
          :title="t('feed.onBehalf')"
          :author="postData.author"
          :group-id="selectedGroupToPost?.id"
          @on-select-change="selectOnBehalfUser"
        />

        <div class="submit">
          <feed-send-button
            :is-loading="isSendLoading"
            :disabled="disabledToSend"
            :post-type="postType"
            @on-send-click="actionSend"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { IonButton, IonButtons, IonItem, IonTextarea, IonToolbar } from '@ionic/vue';
import { useEventBus } from '@vueuse/core';
import { cloneDeep, debounce, filter } from 'lodash';
import type { ComponentPublicInstance, ComputedRef, PropType } from 'vue';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import {
  AppDragZone,
  AppGroupSelectButton,
  AppMentionsChips,
  AppSchedulePostButton,
  AppUserSelectButton,
  CloseBlockWrapper,
  FeedPostPhoto,
  FeedSendButton,
  FileIconPreview,
  FileInfo,
  IconsProvider,
  MediaImage,
  TaskManagementProjectSwitchButton,
} from '@/components';
import {
  AppIconsEnum,
  DocsMenuActionEnum,
  DocumentTypeEnum,
  EventBusEnum,
  FeedEventAnswerEnum,
  FeedTypeEnum,
  GroupAutoSelectModeEnum,
  GroupSelectAccessEnum,
  IdeaStatusEnum,
  MentionModeEnum,
  PostTypeActionEnum,
  PostUploadFileEnum,
  UploadFileTypes,
  UserRoleEnum,
} from '@/enums';
import {
  openDocsAttachmentModal,
  openDocsOfficeChooseTypeModal,
  openPostPreviewModal,
  openWikiCreateModal,
  DateHelper,
  isAnyMobile,
  keyUpSend,
  useFilesHybrid,
  useToasts,
  toShortUserModel,
  useDocs,
  openDocsCreateModal,
  docCreateMenuSheet,
  openDocsCreateMenuModal,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { defaultPost } from '@/models';
import { ROUTES_NAME } from '@/router';
import {
  usePostStore,
  useEventStore,
  useNetworkStore,
  useUserStore,
  useProjectsStore,
  useWikiStore,
  useGroupsStore,
} from '@/store';
import type {
  CommentsDataModel,
  DocModel,
  FileModel,
  GroupModel,
  MediaModel,
  NetworkFullSettingsModel,
  PostModel,
  RequestAnnouncementCreateModel,
  RequestAnnouncementEditModel,
  RequestBadgeCreateModel,
  RequestBadgeFullCreateModel,
  RequestCommentCreateModel,
  RequestEventCreateModel,
  RequestIdeaCreateModel,
  RequestIdeaEditModel,
  RequestPollCreateModel,
  RequestPollEditModel,
  RequestPostEditModel,
  RequestStandardPostCreateModel,
  RequestTaskCommentCreateModel,
  TaskManagementCreateTaskRequestModel,
  UserCurrentModel,
  UserShortModel,
  WikiModel,
  UserModel,
  OfficeFileModel,
  DocsUploadFileMenuModel,
  PostUploadFileMenuModel,
} from '@/types';
import { useLinksStore } from '@/store';
import { isFileGuard, isOfficeFileGuard } from '@/helpers/guards';

const props = defineProps({
  postId: {
    type: Number,
    default: 0,
  },
  postType: {
    type: String as PropType<PostTypeActionEnum>,
    required: true,
  },
  notValidData: {
    type: Boolean,
    default: false,
  },
  postData: {
    type: Object as PropType<PostModel>,
    default: null,
  },
  ideaData: {
    type: Object as PropType<RequestIdeaCreateModel>,
    default: null,
  },
  badgeData: {
    type: Object as PropType<RequestBadgeFullCreateModel>,
    default: null,
  },
  updateData: {
    type: Object as PropType<RequestStandardPostCreateModel>,
    default: null,
  },
  eventData: {
    type: Object as PropType<RequestEventCreateModel>,
    default: null,
  },
  announcementData: {
    type: Object as PropType<RequestAnnouncementCreateModel>,
    default: null,
  },
  pollData: {
    type: Object as PropType<RequestPollCreateModel>,
    default: null,
  },
  taskData: {
    type: Object as PropType<TaskManagementCreateTaskRequestModel>,
    default: null,
  },
  taskId: {
    type: Number,
    default: 0,
  },
  keyUpAction: {
    type: Boolean,
    default: false,
  },
  announcementCurrentPhoto: {
    type: String || null,
    default: null,
  },
  isModal: {
    type: Boolean,
    default: false,
  },
  attachedClipboardFiles: {
    type: FileList || null,
    default: null,
  },
});

const emit = defineEmits(['onSendSuccessful', 'onCommentFocus']);

//#region Variables
const { t } = useI18n();
const { showSonnerToast } = useToasts();
const route = useRoute();

const postStore = usePostStore();
const eventStore = useEventStore();
const groupStore = useGroupsStore();
const networkStore = useNetworkStore();
const userStore = useUserStore();
const projectsStore = useProjectsStore();
const linksStore = useLinksStore();

const caret = ref(0);
const selectedGroupToPost = ref<GroupModel | null>(null);
const coverDeleteFlag = ref<boolean>(false);

const postPhotoObj = ref<MediaModel | null>(null);
const postPhoto = ref<string>('');
const text = ref<string>('');
const coverImage = ref<string>(props.announcementCurrentPhoto);

const isPhotoLoading = ref<boolean>(false);
const isSendLoading = ref<boolean>(false);
const isAttachmentChanged = ref<boolean>(false);
const isOnBehalfUserChanged = ref<boolean>(false);
const isSelectedGroupChanged = ref<boolean>(false);
const accessToGroupSelectActions = ref<GroupSelectAccessEnum[]>([]);
const groupAutoSelectMode = ref<GroupAutoSelectModeEnum>(GroupAutoSelectModeEnum.None);

const textAreaRef = ref<ComponentPublicInstance | null>(null);
const textAreaEl = ref<HTMLTextAreaElement | null>(null);

const files = ref<FileModel[]>([]);
const tempFiles = ref<(FileModel | OfficeFileModel)[]>([]);
const tempOriginFiles = ref<FileModel[]>([]);
const attachedFiles = ref<FileModel[]>([]);
const attachedOriginFiles = ref<FileModel[]>([]);
const attachedWikis = ref<WikiModel[]>([]);
const attachedOriginWikis = ref<WikiModel[]>([]);
const selectedDate = ref<string>('');
const onBehalfUser = ref<UserShortModel | undefined>(props.postData?.author);
const dragIsActive = ref<boolean>(false);
const isModalOpen = ref(false);

const mentionsIsActive = ref(false);

//NOTE: #1682 OnlyOffice attachment content loss - fast fix
const onlyOfficeFileHere = ref<boolean>(false);
const routeName: ComputedRef<string> = computed(() => (route.name as string) || '');
const showScheduleButton: ComputedRef<boolean> = computed(() => routeName.value !== ROUTES_NAME.GROUP_BY_ID);
const isFilesLoading: ComputedRef<boolean> = computed(() => useFilesHybrid().isLoading.value);
const draftTempFiles: ComputedRef<FileModel[]> = computed(() => postStore.draftPosts.tempFiles);
const draftExistFiles: ComputedRef<FileModel[]> = computed(() => postStore.draftPosts.existFiles);
const draftWikis: ComputedRef<WikiModel[]> = computed(() => postStore.draftPosts.wikis);
const uploads: ComputedRef<FileModel[]> = computed(() => files.value.filter((f: FileModel) => f.key !== ''));
const currentUser: ComputedRef<UserCurrentModel | null> = computed(() => userStore.current);
const currentUserRoleId: ComputedRef<number> = computed(() => userStore.current?.roleId ?? 0);
const networkAllowPostToFeed: ComputedRef<boolean> = computed(() => networkStore.settings?.allowPostToFeed ?? false);
const isCurrentPostPlanned: ComputedRef<boolean> = computed(() => !!props.postData?.plannedPostData);
const isDragZoneDisplayed = computed(() => {
  return (
    props.postType !== PostTypeActionEnum.Badge &&
    !isAnyMobile &&
    (dragIsActive.value || dropLoading.value) &&
    !(!props.isModal && isModalOpen.value)
  );
});

const uploadFileMenuItems: ComputedRef<PostUploadFileMenuModel[] | DocsUploadFileMenuModel[]> = computed(() =>
  useDocs().uploadFileMenuItems(selectedGroupToPost.value?.id ?? null, false)
);

const currentNetworkSettings: ComputedRef<NetworkFullSettingsModel | null> = computed(
  () => networkStore.getCurrentSettings
);

const isPostOnBehalfAvailable: ComputedRef<boolean | undefined> = computed(
  () => currentNetworkSettings.value?.allowPostOnBehalf && currentUserRoleId.value >= UserRoleEnum.Moderator
);

const somethingIsAttached: ComputedRef<boolean> = computed(
  () =>
    attachedFiles.value.length > 0 ||
    attachedWikis.value.length > 0 ||
    tempFiles.value.length > 0 ||
    files.value.length > 0
);
const nothingToPush = computed(() => !somethingIsAttached.value && text.value.trim().length === 0);

const checkAttachments: ComputedRef<boolean> = computed(() => {
  switch (props.postType) {
    case PostTypeActionEnum.EditBadge:
    case PostTypeActionEnum.EditText:
    case PostTypeActionEnum.EditIdea:
    case PostTypeActionEnum.EditPoll:
    case PostTypeActionEnum.EditEvent:
      return true;

    default:
      return false;
  }
});

const disabledToSend: ComputedRef<boolean> = computed(() => {
  const { postType, notValidData } = props;

  //NOTE: if user's role is 30 or more, he can post to the feed
  const userHasRights: boolean = !!currentUser.value?.roleId && currentUser.value?.roleId >= UserRoleEnum.User;

  //NOTE: if group is selected - user can post to the feed. If not - user can post to the feed only if he has rights and network allow to post to the feed
  const allowToPost: boolean = selectedGroupToPost.value ? true : userHasRights && networkAllowPostToFeed.value;

  const validTextIsPresent: boolean =
    (props.updateData && props.updateData.text.trim().length > 0 && !notValidData) ||
    (!props.updateData && !notValidData);

  const somethingIsLoading: boolean = isFilesLoading.value || isPhotoLoading.value || isSendLoading.value;

  const standardCase: boolean =
    allowToPost && !somethingIsLoading && (validTextIsPresent || somethingIsAttached.value || uploads.value.length > 0);

  const editCase: boolean =
    !somethingIsLoading &&
    (validTextIsPresent || isAttachmentChanged.value || isOnBehalfUserChanged.value || isSelectedGroupChanged.value);

  const editAnnouncementCase: boolean =
    !somethingIsLoading &&
    (!notValidData ||
      isAttachmentChanged.value ||
      coverDeleteFlag.value ||
      postPhoto.value.length > 0 ||
      isOnBehalfUserChanged.value ||
      isSelectedGroupChanged.value);

  switch (postType) {
    case PostTypeActionEnum.Text:
      return !standardCase;

    case PostTypeActionEnum.Badge:
      return notValidData || somethingIsLoading;

    case PostTypeActionEnum.EditAnnouncement:
      return !editAnnouncementCase;

    case PostTypeActionEnum.EditBadge:
    case PostTypeActionEnum.EditText:
    case PostTypeActionEnum.EditIdea:
    case PostTypeActionEnum.EditPoll:
    case PostTypeActionEnum.EditEvent:
      return !editCase;

    default:
      return notValidData || somethingIsLoading || !allowToPost;
  }
});
//#endregion

//#region Methods
const getSelectedPhotoId = (id: string) => {
  postPhoto.value = id;
  coverImage.value = id;
  coverDeleteFlag.value = false;
};

const getSelectedPhotoObj = (image: MediaModel) => {
  postPhotoObj.value = image;
};

const photoLoading = (loading: boolean) => {
  isPhotoLoading.value = loading;
};

const deleteCoverImage = () => {
  postPhoto.value = '';
  coverImage.value = '';
  coverDeleteFlag.value = true;
};

const processDate = (date: string) => {
  const partsOfDate = date.split(/[.: ]/);
  const result = new Date(+partsOfDate[2], +partsOfDate[1] - 1, +partsOfDate[0], +partsOfDate[3], +partsOfDate[4]);
  const offset = result.getTimezoneOffset();
  result.setMinutes(result.getMinutes() - offset);
  return result;
};

const actionPreview = async () => {
  const author = {
    id: onBehalfUser.value?.id || currentUser.value?.id,
    fullName: onBehalfUser.value?.fullName || currentUser.value?.fullName,
    mainAlias: onBehalfUser.value?.mainAlias || currentUser.value?.mainAlias,
    isActive: onBehalfUser.value?.isActive || currentUser.value?.isActive,
    avatar: onBehalfUser.value?.image || currentUser.value?.avatar || null,
  };
  const initiator = {
    id: currentUser.value?.id,
    fullName: currentUser.value?.fullName,
    mainAlias: currentUser.value?.mainAlias,
    isActive: currentUser.value?.isActive,
    avatar: currentUser.value?.avatar || null,
  };
  const attachedLinks = linksStore.currentLinksPreviewData;

  const updateData = {
    author: author,
    initiator: initiator,
    bodyHtml: props.updateData?.text.trim() || text.value.trim(),
    attachedFiles: {
      count: attachedFiles.value.length,
      data: attachedFiles.value,
    },
    attachedWikis: {
      count: attachedWikis.value.length,
      data: attachedWikis.value,
    },
    attachedLinks: {
      count: attachedLinks.length,
      data: attachedLinks,
    },
    group: selectedGroupToPost.value,
    plannedPostData: {
      plannedDate: selectedDate.value,
      processStatus: 1,
      tempFiles: [...uploads.value, ...tempFiles.value],
    },
    usersReadCount: 1,
  };
  const data = Object.assign(cloneDeep(defaultPost), updateData);
  data.createdAt = DateHelper.getIsoNow();
  switch (props.postType) {
    case PostTypeActionEnum.Text:
      data.messageType = FeedTypeEnum.Text;
      data.ccUsers = (props.updateData.users as UserShortModel[]) || [];
      break;
    case PostTypeActionEnum.Event:
    case PostTypeActionEnum.CalendarEvent:
      data.bodyHtml = props.eventData?.text.trim() || '';
      data.messageType = FeedTypeEnum.Event;
      data.ccUsers = (props.eventData.users as UserShortModel[]) || [];
      data.eventData = {
        title: props.eventData.title.trim(),
        location: props.eventData.place.trim(),
        datetime: props.eventData.date,
        durationMinutes: props.eventData.duration,
        answer: FeedEventAnswerEnum.None,
        attendingsCount: 0,
        videoChatLink: null,
        isBlocker: props.eventData.isBlocker,
        blockerSubscribers: 0,
        currentUserSubscribed: false,
      };
      break;
    case PostTypeActionEnum.Announcement:
      data.title = props.announcementData.title.trim();
      data.bodyHtml = props.announcementData.text.trim();
      data.image = postPhotoObj.value;
      data.messageType = FeedTypeEnum.Announcement;
      break;
    case PostTypeActionEnum.Poll:
      {
        let dateObj = null;
        if (props.pollData.limitDate) {
          dateObj = processDate(props.pollData.limitDate);
        }
        data.messageType = FeedTypeEnum.Poll;
        data.pollData = {
          mainHtml: props.pollData.text.trim(),
          allowAddOptions: props.pollData.allowUserAddOptions,
          allowSeveralAnswers: props.pollData.allowMultipleAnswers,
          anonymousMode: props.pollData.isAnonymousVoting,
          votes: 0,
          voters: 0,
          isAnswered: true,
          timeLimit: dateObj ? dateObj.toISOString() : '',
          options: props.pollData.questionOptions.map((item) => {
            return {
              text: item,
              isAnswered: false,
              votesCount: 0,
              voteIds: [],
            };
          }),
        };
      }
      break;
    case PostTypeActionEnum.Idea:
      data.messageType = FeedTypeEnum.Idea;
      data.ideaData = {
        title: props.ideaData.title.trim(),
        problem: props.ideaData.problem.trim(),
        solution: props.ideaData.solution.trim(),
        results: props.ideaData.results.trim(),
        status: IdeaStatusEnum.New,
        statusText: 'New',
      };
      data.likes = {
        count: 1,
        data: [],
        isLiked: true,
      };
      break;
    case PostTypeActionEnum.Task:
      data.taskData = {
        id: 0,
        identifier: 0,
        assignee: (props.taskData?.assignee as UserShortModel) || null,
        author: author as unknown as UserShortModel,
        comments: { count: 0, data: [] },
        dateArchived: null,
        dateClosed: null,
        dateCreated: new Date().toISOString(),
        dateDue: props.taskData.dateDue,
        dateLastUpdated: null,
        description: props.taskData.description,
        files: {
          count: attachedFiles.value.length,
          data: attachedFiles.value,
        },
        history: null,
        isArchived: false,
        isClosed: false,
        links: { count: 0, data: null },
        milestone: props.taskData?.milestone || null,
        modifier: null,
        participants: {
          count: 1,
          data: props.taskData.assignee ? [props.taskData.assignee as UserShortModel] : [],
        },
        projectId: props.taskData.projectId || 0,
        tags: {
          count: props.taskData?.tags?.length || 0,
          data: props.taskData?.tags || [],
        },
        title: props.taskData.title.trim(),
        wikis: {
          count: attachedWikis.value.length,
          data: attachedWikis.value,
        },
        type: 'Plug',
        notify: null,
      };
      data.messageType = FeedTypeEnum.Task;
      break;
    case PostTypeActionEnum.Badge:
      data.messageType = FeedTypeEnum.Badge;
      data.attachedBadge = {
        id: props.badgeData.badgeId as number,
        assignedTo: props.badgeData.users as UserShortModel[],
      };
      break;
  }
  const result = await openPostPreviewModal(data, props.notValidData);
  if (result) {
    await actionSend();
  }
};

const actionSend = async () => {
  if (
    (props.postType === PostTypeActionEnum.Comment || props.postType === PostTypeActionEnum.TaskComment) &&
    (isFilesLoading.value || nothingToPush.value)
  ) {
    return;
  }
  if (
    props.postType !== PostTypeActionEnum.Comment &&
    props.postType !== PostTypeActionEnum.TaskComment &&
    disabledToSend.value
  ) {
    return;
  }

  isSendLoading.value = true;

  /**
   * @note In order to prevent OO document loss when saving a post with an attached OO document,
   * we added a 15 second delay before saving the post.
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1682
   * @todo #tech-debt
   */
  if (onlyOfficeFileHere.value) {
    console.log('Awaiting for onlyOffice file creation...');
    await new Promise((resolve) => setTimeout(resolve, 15000));
  }

  const data = {
    text: text.value.trim(),
    files: [...uploads.value, ...tempFiles.value],
    attachedFiles: attachedFiles.value,
    attachedWikis: attachedWikis.value,
    postPhotoId: postPhoto.value,
    groupToPost: selectedGroupToPost.value,
    linksIds: linksStore.currentLinksPreviewData.map((n) => +n.id),
  };
  text.value = ' ';
  files.value = [];
  tempFiles.value = [];
  attachedFiles.value = [];
  attachedWikis.value = [];
  postPhoto.value = '';

  const postTypeActionHandlers: Record<PostTypeActionEnum, (() => Promise<void>) | undefined> = {
    [PostTypeActionEnum.EditBadge]: undefined,
    [PostTypeActionEnum.Comment]: async () => {
      const comment: RequestCommentCreateModel = {
        text: data.text,
        messageId: props.postId,
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
      };
      if (!(await postStore.commentCreate(comment))) {
        showSonnerToast(t('feed.comments.commentNotSend'), false);
      }
    },
    [PostTypeActionEnum.TaskComment]: async () => {
      const comment: RequestTaskCommentCreateModel = {
        text: data.text,
        taskId: props.taskId,
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
      };
      if (!(await projectsStore.taskCommentCreate(comment))) {
        showSonnerToast(t('feed.comments.commentNotSend'), false);
      } else {
        emit('onSendSuccessful');
      }
    },
    [PostTypeActionEnum.Text]: async () => {
      const postData: RequestStandardPostCreateModel = {
        text: props.updateData.text.length === 0 ? ' ' : props.updateData.text.trim(),
        userIds: props.updateData.userIds,
        groupIds: props.updateData.groupIds,
        userEmails: props.updateData.userEmails,
        toGroupId: data.groupToPost?.id || null,
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        linksIds: data.linksIds,
      };
      if (selectedDate.value) {
        postData.plannedDate = selectedDate.value;
      }
      if (onBehalfUser.value) {
        postData.onBehalfUserId = onBehalfUser.value.id;
      }

      const sendPost = async (postData: RequestStandardPostCreateModel) => {
        const result = selectedDate.value
          ? await postStore.postPlannedCreate(postData)
          : await postStore.postCreate(postData);

        if (result) {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              standardPost: null,
            },
          });
          linksStore.$patch({
            currentLinksPreviewData: [],
            currentInternalPostLinksPreviewData: [],
            currentInternalPostLinks: [],
          });
          removeAllFiles();
        } else {
          showSonnerToast(t('feed.postNotSend'), false);
        }
      };
      await sendPost(postData);
    },
    [PostTypeActionEnum.Idea]: async () => {
      const idea: RequestIdeaCreateModel = {
        title: props.ideaData.title.trim(),
        problem: props.ideaData.problem.trim(),
        solution: props.ideaData.solution.trim(),
        results: props.ideaData.results.trim(),
        toGroupID: data.groupToPost?.id || null,
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        fileIds: [],
      };

      if (selectedDate.value) {
        idea.plannedDate = selectedDate.value;
      }
      if (onBehalfUser.value) {
        idea.onBehalfUserId = onBehalfUser.value.id;
      }

      const sendPost = async (idea: RequestIdeaCreateModel) => {
        const result = selectedDate.value ? await postStore.ideaPlannedCreate(idea) : await postStore.ideaCreate(idea);

        if (result) {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              idea: null,
            },
          });
          removeAllFiles();
        } else {
          showSonnerToast(t('feed.postNotSend'), false);
        }
      };
      await sendPost(idea);
    },
    [PostTypeActionEnum.Badge]: async () => {
      const badge: RequestBadgeCreateModel = {
        text: props.badgeData.text.trim(),
        badgeId: props.badgeData.badgeId || 0,
        userIds: props.badgeData.userIds,
      };
      if (!(await postStore.badgeCreate(badge))) {
        showSonnerToast(t('feed.postNotSend'), false);
      } else {
        emit('onSendSuccessful');
        postStore.$patch({
          draftPosts: {
            badge: null,
          },
        });
      }
    },
    [PostTypeActionEnum.Event]: async () => {
      const event: RequestEventCreateModel = {
        title: props.eventData.title.trim(),
        text: props.eventData.text.trim(),
        date: props.eventData.date,
        duration: props.eventData.duration,
        place: props.eventData.place.trim(),
        userIds: props.eventData.userIds,
        groupIds: props.eventData.groupIds,
        userEmails: props.eventData.userEmails,
        toGroupId: data.groupToPost?.id || null,
        endDate: props.eventData.endDate,
        isBlocker: props.eventData.isBlocker,
      };

      if (selectedDate.value) {
        event.plannedDate = selectedDate.value;
      }
      if (onBehalfUser.value) {
        event.onBehalfUserId = onBehalfUser.value.id;
      }

      const sendPost = async (event: RequestEventCreateModel) => {
        const result = selectedDate.value
          ? await postStore.eventPlannedCreate(event)
          : await postStore.eventCreate(event);

        if (result) {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              event: null,
            },
          });
          removeAllFiles();
        } else {
          showSonnerToast(t('feed.postNotSend'), false);
        }
      };

      if (props.postType === PostTypeActionEnum.Event) {
        await sendPost(event);
        // Event in calendar
      } else if (props.postType === PostTypeActionEnum.CalendarEvent) {
        if (!(await eventStore.eventCreate(event))) {
          showSonnerToast(t('feed.event.eventNotSend'), false);
        } else {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              event: null,
            },
          });
          removeAllFiles();
        }
      }
    },
    [PostTypeActionEnum.CalendarEvent]: async () => {
      const event: RequestEventCreateModel = {
        title: props.eventData.title.trim(),
        text: props.eventData.text.trim(),
        date: props.eventData.date,
        duration: props.eventData.duration,
        place: props.eventData.place.trim(),
        userIds: props.eventData.userIds,
        groupIds: props.eventData.groupIds,
        userEmails: props.eventData.userEmails,
        toGroupId: data.groupToPost?.id || null,
        endDate: props.eventData.endDate,
        isBlocker: props.eventData.isBlocker,
      };

      if (selectedDate.value) {
        event.plannedDate = selectedDate.value;
      }
      if (onBehalfUser.value) {
        event.onBehalfUserId = onBehalfUser.value.id;
      }

      const sendPost = async (event: RequestEventCreateModel) => {
        const result = selectedDate.value
          ? await postStore.eventPlannedCreate(event)
          : await postStore.eventCreate(event);

        if (result) {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              event: null,
            },
          });
          removeAllFiles();
        } else {
          showSonnerToast(t('feed.postNotSend'), false);
        }
      };

      if (props.postType === PostTypeActionEnum.Event) {
        await sendPost(event);
        // Event in calendar
      } else if (props.postType === PostTypeActionEnum.CalendarEvent) {
        if (!(await eventStore.eventCreate(event))) {
          showSonnerToast(t('feed.event.eventNotSend'), false);
        } else {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              event: null,
            },
          });
          removeAllFiles();
        }
      }
    },
    [PostTypeActionEnum.Announcement]: async () => {
      const announcement: RequestAnnouncementCreateModel = {
        title: props.announcementData.title.trim(),
        text: props.announcementData.text.trim(),
        isRequiredToRead: props.announcementData.isRequiredToRead,
        imageId: data.postPhotoId,
        groupId: data.groupToPost?.id || null,
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
      };

      if (selectedDate.value) {
        announcement.plannedDate = selectedDate.value;
      }
      if (onBehalfUser.value) {
        announcement.onBehalfUserId = onBehalfUser.value.id;
      }

      const sendPost = async (announcement: RequestAnnouncementCreateModel) => {
        const result = selectedDate.value
          ? await postStore.announcementPlannedCreate(announcement)
          : await postStore.announcementCreate(announcement);

        if (result) {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              announcement: null,
            },
          });
          removeAllFiles();
        } else {
          showSonnerToast(t('feed.postNotSend'), false);
        }
      };
      await sendPost(announcement);
    },
    [PostTypeActionEnum.Poll]: async () => {
      const poll: RequestPollCreateModel = {
        text: props.pollData.text.trim(),
        allowUserAddOptions: props.pollData.allowUserAddOptions,
        allowMultipleAnswers: props.pollData.allowMultipleAnswers,
        isAnonymousVoting: props.pollData.isAnonymousVoting,
        limitDate: props.pollData.limitDate,
        questionOptions: props.pollData.questionOptions,
        toGroupId: data.groupToPost?.id,
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
      };

      if (selectedDate.value) {
        poll.plannedDate = selectedDate.value;
      }
      if (onBehalfUser.value) {
        poll.onBehalfUserId = onBehalfUser.value.id;
      }

      const sendPost = async (poll: RequestPollCreateModel) => {
        const result = selectedDate.value ? await postStore.pollPlannedCreate(poll) : await postStore.pollCreate(poll);

        if (result) {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              poll: null,
            },
          });
          removeAllFiles();
        } else {
          showSonnerToast(t('feed.postNotSend'), false);
        }
      };
      await sendPost(poll);
    },
    [PostTypeActionEnum.Task]: async () => {
      const task: TaskManagementCreateTaskRequestModel = {
        title: props.taskData.title.trim(),
        tagsIds: props.taskData.tagsIds,
        dateDue: props.taskData.dateDue,
        assigneeId: props.taskData.assigneeId,
        milestoneId: props.taskData.milestoneId,
        description: props.taskData.description.trim(),
        projectId: props.taskData.projectId,
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        participantsIds: props.taskData.participantsIds,
      };
      if (!(await projectsStore.taskCreate(task))) {
        showSonnerToast(t('feed.postNotSend'), false);
      } else {
        emit('onSendSuccessful');
        postStore.$patch({
          draftPosts: {
            task: null,
          },
        });
        removeAllFiles();
      }
    },
    [PostTypeActionEnum.EditText]: async () => {
      const editData: RequestPostEditModel = {
        text: props.updateData.text.length === 0 ? ' ' : props.updateData.text.trim(),
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        toGroupId: data.groupToPost?.id || null,
        linksIds: data.linksIds,
        onBehalfUserId: onBehalfUser.value?.id,
      };

      if (props.postData?.plannedPostData?.plannedDate) {
        editData.plannedDate = props.postData?.plannedPostData?.plannedDate;
      }

      const updatePost = async (editData: RequestPostEditModel) => {
        const result =
          props.postData?.plannedPostData !== null
            ? await postStore.postPlannedUpdate(props.postId, editData)
            : await postStore.postEdit(props.postId, editData);

        if (result) {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postEdited'), true);
          emit('onSendSuccessful');
        } else {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postNotEdited'), false);
        }
      };
      await updatePost(editData);
    },
    [PostTypeActionEnum.EditEvent]: async () => {
      const event: RequestEventCreateModel = {
        title: props.eventData.title.trim(),
        text: props.eventData.text.trim(),
        date: props.eventData.date,
        duration: props.eventData.duration,
        place: props.eventData.place.trim(),
        /* userIds: props.eventData.userIds,
        groupIds: props.eventData.groupIds,
        userEmails: props.eventData.userEmails, */
        endDate: props.eventData.endDate,
        isBlocker: props.eventData.isBlocker,
        toGroupId: data.groupToPost?.id || null,
        onBehalfUserId: onBehalfUser.value?.id,
      };

      if (props.postData?.plannedPostData?.plannedDate) {
        event.plannedDate = props.postData?.plannedPostData?.plannedDate;
      }

      const updatePost = async (event: RequestEventCreateModel) => {
        const result =
          props.postData?.plannedPostData !== null
            ? await postStore.eventPlannedUpdate(props.postId, event)
            : await postStore.eventEdit(props.postId, event);

        if (result) {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postEdited'), true);
          emit('onSendSuccessful');
        } else {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postNotEdited'), false);
        }
      };
      await updatePost(event);
    },
    [PostTypeActionEnum.EditIdea]: async () => {
      const idea: RequestIdeaCreateModel = {
        title: props.ideaData.title.trim(),
        problem: props.ideaData.problem.trim(),
        solution: props.ideaData.solution.trim(),
        results: props.ideaData.results.trim(),
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        fileIds: [],
        toGroupID: data.groupToPost?.id || null,
        onBehalfUserId: onBehalfUser.value?.id,
      };

      if (props.postData?.plannedPostData?.plannedDate) {
        idea.plannedDate = props.postData?.plannedPostData?.plannedDate;
      }

      const updatePost = async (idea: RequestIdeaEditModel) => {
        const result =
          props.postData?.plannedPostData !== null
            ? await postStore.ideaPlannedUpdate(props.postId, idea)
            : await postStore.ideaEdit(props.postId, idea);

        if (result) {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postEdited'), true);
          emit('onSendSuccessful');
        } else {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postNotEdited'), false);
        }
      };

      await updatePost(idea);
    },
    [PostTypeActionEnum.EditPoll]: async () => {
      const poll: RequestPollEditModel = {
        text: props.pollData.text.trim(),
        limitDate: props.postData.pollData?.timeLimit
          ? DateHelper.formatDateToISO(new Date(DateHelper.getUtcZero(props.postData.pollData.timeLimit)))
          : '',
        questionOptions: props.postData.pollData?.options.map((option) => option.text.trim()) || [''],
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        toGroupId: data.groupToPost?.id || null,
        onBehalfUserId: onBehalfUser.value?.id,
      };

      if (props.postData?.plannedPostData?.plannedDate) {
        poll.plannedDate = props.postData?.plannedPostData?.plannedDate;
      }

      const updatePost = async (poll: RequestPollEditModel) => {
        const result =
          props.postData?.plannedPostData !== null
            ? await postStore.pollPlannedUpdate(props.postId, poll)
            : await postStore.pollEdit(props.postId, poll);

        if (result) {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postEdited'), true);
          emit('onSendSuccessful');
        } else {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postNotEdited'), false);
        }
      };
      await updatePost(poll);
    },
    [PostTypeActionEnum.EditAnnouncement]: async () => {
      const announcement: RequestAnnouncementCreateModel = {
        title: props.announcementData.title.trim(),
        text: props.announcementData.text.trim(),
        isRequiredToRead: props.announcementData.isRequiredToRead,
        imageId: data.postPhotoId,
        groupId: data.groupToPost?.id || null,
        fileTempIds: data.files.map((f: FileModel | OfficeFileModel) => f.key),
        fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
        wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        onBehalfUserId: onBehalfUser.value?.id,
      };

      if (props.postData?.plannedPostData?.plannedDate) {
        announcement.plannedDate = props.postData?.plannedPostData?.plannedDate;
      }

      const updatePost = async (announcement: RequestAnnouncementEditModel) => {
        const result =
          props.postData?.plannedPostData !== null
            ? await postStore.announcementPlannedUpdate(props.postId, announcement)
            : await postStore.announcementEdit(props.postId, announcement);

        if (result) {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postEdited'), true);
          emit('onSendSuccessful');
        } else {
          showSonnerToast(t('feed.feedItemPostMenu.edit.postNotEdited'), false);
        }
      };
      await updatePost(announcement);
    },
  };

  const handler = postTypeActionHandlers[props.postType];

  if (!handler) {
    console.warn('[WARN] No handler found for post action type: ', props.postType);
    return;
  }

  try {
    await handler();
  } catch (error) {
    console.error(`[ERROR] Error executing creating ${props.postType} post:`, error);
    showSonnerToast(t('errorResponse'), false);
  }

  //NOTE: #1682 OnlyOffice attachment content loss - fast fix
  onlyOfficeFileHere.value = false;
  isSendLoading.value = false;
};

const keyUp = (event: KeyboardEvent | MouseEvent) => {
  if (keyUpSend(event)) actionSend();
};

const isAttachmentsEqual = () => {
  const allAttachedFiles = [...attachedFiles.value, ...files.value];
  const attachedFilesEqual =
    attachedOriginFiles.value.length === allAttachedFiles.length &&
    attachedOriginFiles.value.every((attachedFile) =>
      allAttachedFiles.find((newAttachedFile) => newAttachedFile.key === attachedFile.key)
    );
  const attachedWikisEqual =
    attachedOriginWikis.value.length === attachedWikis.value.length &&
    attachedOriginWikis.value.every((attachedWiki) =>
      attachedWikis.value.find((newAttachedWiki) => newAttachedWiki.id === attachedWiki.id)
    );
  const tempFilesEqual =
    tempOriginFiles.value.length === tempFiles.value.length &&
    tempOriginFiles.value.every((tempFile) => tempFiles.value.find((newTempFile) => newTempFile.key === tempFile.key));
  return attachedFilesEqual && attachedWikisEqual && tempFilesEqual;
};

const fromDevice = async (): Promise<boolean> => {
  // Picking file
  const attachFiles = await useFilesHybrid().pickFiles(UploadFileTypes.ManyDifferentFiles);

  if (!attachFiles.length) {
    console.error('No files were picked from device');
    return false;
  }
  // Saving file
  if (attachFiles.length > 0) {
    let attached = [];
    for (let i = 0; i < attachFiles.length; i++) {
      const _file = await useFilesHybrid().toFile(attachFiles[i]);
      if (_file !== undefined) {
        files.value.push(_file);
        attached.push({
          index: i,
          payload: _file.payload,
        });
      } else {
        showSonnerToast(
          t('uploadFileIsLarger', {
            size: import.meta.env.VITE_APP_MAX_SIZE,
          }),
          false
        );
      }
    }

    if (!attached.length) {
      console.error('No files were uploaded');
      return false;
    }

    // Uploading file
    attached = attached.map(async (file) => {
      const uploadFile = await useFilesHybrid().uploadFile(attachFiles[file.index]);
      const index = files.value.findIndex((f) => f.payload === file.payload);
      if (~index && uploadFile !== undefined) {
        files.value.splice(index, 1, uploadFile);
        return file;
      }
      return null;
    });

    if (attached.every((file) => file === null)) {
      console.error('No files were uploaded');
      return false;
    }
  }

  return true;
};

const fromAttachment = async (): Promise<boolean> => {
  //NOTE: If the post is created - take the group from the selector. If the post is created - take the group from the post
  let groupData = null;
  if (selectedGroupToPost.value) {
    groupData = selectedGroupToPost.value;
  } else if (props.postData?.group) {
    groupData = props.postData.group ? groupStore.getGroupById(props.postData.group.id) : null;
  }

  const result = await openDocsAttachmentModal(groupData, false);

  if (result === undefined) {
    console.error('No files were attached');
    return false;
  }

  const data = result as DocModel[];
  data.forEach((element) => {
    if (element.documentType === DocumentTypeEnum.Wiki) {
      if (!attachedWikis.value.some((o) => o.id === element.data.id)) {
        attachedWikis.value = [element.data as WikiModel, ...attachedWikis.value];
        postStore.$patch({
          draftPosts: {
            wikis: attachedWikis.value,
          },
        });
      }
    } else {
      if (!attachedFiles.value.some((o) => o.id === element.data.id)) {
        attachedFiles.value = [element.data as FileModel, ...attachedFiles.value];
        if (props.postType !== PostTypeActionEnum.Comment && props.postType !== PostTypeActionEnum.TaskComment) {
          postStore.$patch({
            draftPosts: {
              existFiles: attachedFiles.value,
            },
          });
        }
      }
    }
  });

  return true;
};

const createDocument = async (): Promise<boolean> => {
  const chooseOfficeTypeResult = await openDocsOfficeChooseTypeModal();

  if (!chooseOfficeTypeResult) return false;

  const payload = {
    action: DocsMenuActionEnum.CreateFile,
    title: t('office.enterName'),
    type: chooseOfficeTypeResult,
    withAutoSave: false,
    groupId: selectedGroupToPost.value?.id,
  };

  const result = await openDocsCreateModal(payload);

  if (!result) {
    console.error('Create document error');
    return false;
  }

  /** @note #1682 OnlyOffice attachment content loss - fast fix */
  onlyOfficeFileHere.value = true;

  if (isOfficeFileGuard(result)) {
    tempFiles.value.push(result);
  }

  if (props.postType !== PostTypeActionEnum.Comment && props.postType !== PostTypeActionEnum.TaskComment) {
    postStore.$patch({
      draftPosts: {
        tempFiles: attachedFiles.value,
      },
    });
  }

  return true;
};

const createWiki = async (): Promise<boolean> => {
  const result = await openWikiCreateModal(
    selectedGroupToPost.value?.id,
    undefined /** @todo We should pass the group id and folder id */,
    true /** @note In publisher wiki creation is always isModal */,
    onBehalfUser.value
  );

  if (!result) {
    console.error('Create wiki error');
    return false;
  }

  const newWiki = await useWikiStore().wikiById(result, false);
  if (!newWiki) {
    console.error('Create wiki error');
    return false;
  }

  attachedWikis.value.push(newWiki);
  if (props.postType !== PostTypeActionEnum.Comment && props.postType !== PostTypeActionEnum.TaskComment) {
    postStore.$patch({
      draftPosts: {
        wikis: attachedWikis.value,
      },
    });
  }

  return true;
};

const attachAction = async () => {
  if (props.postType === PostTypeActionEnum.Poll || props.postType === PostTypeActionEnum.EditPoll) {
    try {
      await fromDevice();
    } catch (e) {
      console.error('fromDevice error: ', e);
    }

    if (checkAttachments.value) {
      isAttachmentChanged.value = !isAttachmentsEqual();
    }

    return;
  }

  const data = isAnyMobile
    ? await docCreateMenuSheet(uploadFileMenuItems.value)
    : await openDocsCreateMenuModal(uploadFileMenuItems.value);

  if (!data) return;

  const actions: Partial<Record<PostUploadFileEnum | DocsMenuActionEnum, () => Promise<boolean>>> = {
    [PostUploadFileEnum.UploadFromDevice]: async () => fromDevice(),
    [PostUploadFileEnum.UploadFromGroup]: async () => fromAttachment(),
    [PostUploadFileEnum.CreateDocument]: async () => createDocument(),
    [PostUploadFileEnum.CreateWiki]: async () => createWiki(),
  };

  const action = actions[data];
  if (!action) {
    console.warn('No action found:', data);
    return;
  }

  try {
    await action();
  } catch (error) {
    console.error(`[ERROR] Error executing action ${data}:`, error);
    showSonnerToast(t('errorResponse'), false);
  }

  if (checkAttachments.value) {
    isAttachmentChanged.value = !isAttachmentsEqual();
  }
};

const attachFromClipboard = async (fileList: FileList) => {
  let attached = [];
  for (let i = 0; i < fileList.length; i++) {
    const _file = await useFilesHybrid().toFile(fileList[i]);
    if (_file !== undefined) {
      files.value.push(_file);
      attached.push({
        index: i,
        payload: _file.payload,
      });
    } else {
      showSonnerToast(
        t('uploadFileIsLarger', {
          size: import.meta.env.VITE_APP_MAX_SIZE,
        }),
        false
      );
    }
  }

  if (!attached.length) {
    throw new Error('No files were uploaded');
  }

  attached = await Promise.all(
    attached.map(async (file) => {
      const uploadFile = await useFilesHybrid().uploadWeb(fileList[file.index]);
      const index = files.value.findIndex((f) => f.payload === file.payload);
      if (~index && uploadFile !== undefined) {
        files.value.splice(index, 1, uploadFile);
        return file;
      }
      return null;
    })
  );
};

const selectChange = (groupData: GroupModel | null) => {
  selectedGroupToPost.value = groupData;

  /** @note Remove attachments from other sources
  Do not remove files downloaded from the device, as they do not have their own location */

  const filterByGroupId = (item: any) => item.group?.id === groupData?.id;
  const filterByNullGroup = (item: any) => item.group === null;

  /* files.value = []; */
  tempFiles.value = groupData?.id
    ? filter(tempFiles.value, filterByGroupId)
    : filter(tempFiles.value, filterByNullGroup);
  attachedFiles.value = groupData?.id
    ? filter(attachedFiles.value, filterByGroupId)
    : filter(attachedFiles.value, filterByNullGroup);
  attachedWikis.value = groupData?.id
    ? filter(attachedWikis.value, filterByGroupId)
    : filter(attachedWikis.value, filterByNullGroup);
};

const commentFocus = async () => {
  textAreaEl.value = await textAreaRef.value?.$el.getInputElement();
  setMentionsActive();
  emit('onCommentFocus', true);
};

const selectDate = (date: string) => {
  selectedDate.value = date;
};

const selectOnBehalfUser = (user: UserModel | undefined) => {
  onBehalfUser.value = user ? toShortUserModel(user) : undefined;
};

const commentBlur = async () => {
  resetMentionsActive();
  emit('onCommentFocus', false);
};

const setMentionsActive = debounce(async () => {
  mentionsIsActive.value = true;
}, 150);

const resetMentionsActive = debounce(async () => {
  mentionsIsActive.value = false;
}, 150);

const setFieldText = async (comment: CommentsDataModel | null) => {
  if (props.postType === PostTypeActionEnum.Comment && comment) {
    text.value = '@' + comment.author.mainAlias + ', ';
  }
};

const setFieldFocus = async () => {
  await textAreaRef.value?.$el.setFocus();
};

const onDeleteFile = async (file: FileModel | any) => {
  files.value = files.value.filter((f: FileModel) => f.key !== file.key);
  tempFiles.value = tempFiles.value.filter((f: FileModel | OfficeFileModel) => f.key !== file.key);
  attachedFiles.value = attachedFiles.value.filter((f: FileModel) => f.id !== file.id);
  attachedWikis.value = attachedWikis.value.filter((f: any) => f.id !== file.id);
  postStore.$patch({
    draftPosts: {
      tempFiles: files.value,
      existFiles: attachedFiles.value,
      wikis: attachedWikis.value,
    },
  });
  await useFilesHybrid().remove(file.key);
  if (checkAttachments.value) {
    isAttachmentChanged.value = !isAttachmentsEqual();
  }
};

const onDeleteWiki = async (file: WikiModel) => {
  attachedWikis.value = attachedWikis.value.filter((f: WikiModel) => f.id !== file.id);
  postStore.$patch({
    draftPosts: {
      wikis: attachedWikis.value,
    },
  });
  if (checkAttachments.value) {
    isAttachmentChanged.value = !isAttachmentsEqual();
  }
};

const removeAllFiles = () => {
  files.value = [];
  tempFiles.value = [];
  attachedFiles.value = [];
  attachedWikis.value = [];

  //NOTE: #1682 OnlyOffice attachment content loss - fast fix
  onlyOfficeFileHere.value = false;

  postStore.$patch({
    draftPosts: {
      tempFiles: files.value,
      existFiles: attachedFiles.value,
      wikis: attachedWikis.value,
    },
  });
};

const onChange = async () => {
  if (textAreaEl.value !== null) {
    caret.value = textAreaEl.value.selectionStart;
  }
};

const onAppend = async (data: { alias: string; index: number }) => {
  const message = text.value.substring(data.index);
  const indexSpace = message.indexOf(' ');

  const startMessage = text.value.substring(0, data.index + 1) + data.alias + ' ';

  if (~indexSpace) {
    text.value = startMessage + message.substring(indexSpace);
    await textAreaRef.value?.$el.setFocus();
    const nativeEl = await textAreaRef.value?.$el.getInputElement();
    nativeEl.setSelectionRange(startMessage.length, startMessage.length);
  } else {
    text.value = startMessage;
    await textAreaRef.value?.$el.setFocus();
  }
  caret.value = text.value.length;
};
//#endregion

//#region  Publisher Drop Zone
const dropLoading = ref<boolean>(false);
const { on: onPublisherDropZone } = useEventBus<{ isActive: boolean; isModalOpen: boolean }>(
  EventBusEnum.PublisherDropZone
);
onPublisherDropZone(({ isActive, isModalOpen: eventIsModalOpen }) => {
  isModalOpen.value = eventIsModalOpen;
  dragIsActive.value = isActive;
});
const droppedFiles = (filesArray: FileModel[]) => {
  files.value.push(...filesArray.filter((newFile) => !files.value.some((file) => file.key === newFile.key)));
};
const dropIsLoading = (loading: boolean) => {
  dropLoading.value = !loading;
};
//#endregion

//#region Expose
defineExpose({
  setFieldText,
  setFieldFocus,
});
//#endregion

//#region Watchers
const keyUpAction: ComputedRef<boolean> = computed(() => props.keyUpAction);
watch(keyUpAction, () => {
  if (keyUpAction.value) actionSend();
});

watch(uploads, () => {
  if (props.postType !== PostTypeActionEnum.Comment && props.postType !== PostTypeActionEnum.TaskComment) {
    postStore.$patch({
      draftPosts: {
        tempFiles: uploads.value,
      },
    });
  }
});

watch(onBehalfUser, (newValue, oldValue) => {
  /** @note isOnBehalfUserChanged flag must remain false when onBehalfUser has been set in OnMounted hook in case of editing post */
  if (newValue?.id === props.postData?.author?.id) {
    isOnBehalfUserChanged.value = false;
    return;
  }
  isOnBehalfUserChanged.value = newValue !== oldValue;
});

watch(selectedGroupToPost, (newValue, oldValue) => {
  isSelectedGroupChanged.value = newValue !== oldValue;
});

watch(
  () => props.attachedClipboardFiles,
  async (values) => {
    if (values) attachFromClipboard(values);
  }
);
//#endregion

//#region Lifecycle
onMounted(async () => {
  switch (props.postType) {
    case PostTypeActionEnum.Text:
    case PostTypeActionEnum.Event:
    case PostTypeActionEnum.CalendarEvent:
    case PostTypeActionEnum.Announcement:
    case PostTypeActionEnum.Poll:
    case PostTypeActionEnum.Idea:
      {
        tempFiles.value = draftTempFiles.value;
        attachedFiles.value = draftExistFiles.value;
        attachedWikis.value = draftWikis.value;
      }

      break;
    case PostTypeActionEnum.EditText:
    case PostTypeActionEnum.EditEvent:
    case PostTypeActionEnum.EditIdea:
    case PostTypeActionEnum.EditPoll:
    case PostTypeActionEnum.EditAnnouncement: {
      if (props.postData?.group) {
        let groupFullModel: GroupModel | null = groupStore.getGroupById(props.postData.group.id);
        if (groupFullModel.id === 0) {
          groupFullModel = (await groupStore.groupById(props.postData.group.id)) || null;
        }
        selectedGroupToPost.value = groupFullModel;
      }
      attachedOriginFiles.value = props.postData.attachedFiles.data;
      attachedOriginWikis.value = props.postData.attachedWikis.data;
      attachedFiles.value = props.postData.attachedFiles.data;
      attachedWikis.value = props.postData.attachedWikis.data;

      /** @note In case of editing post value from postData must be assigned by default
       * If onBehalfUser.value !== undefined it means that user has changed or deleted on behalf user, so current value is valid*/
      if (onBehalfUser.value === undefined) {
        onBehalfUser.value =
          props.postData?.initiator && props.postData?.initiator.id !== props.postData.author.id
            ? props.postData.author
            : undefined;
      }

      if (props.postData?.plannedPostData !== null) {
        tempOriginFiles.value = props.postData.plannedPostData.tempFiles;
        tempFiles.value = props.postData.plannedPostData.tempFiles;
      }
    }
  }
  switch (route.name) {
    case ROUTES_NAME.PROJECT_BY_ID: {
      accessToGroupSelectActions.value = [];
      groupAutoSelectMode.value = GroupAutoSelectModeEnum.FromCurrentProject;
      break;
    }
    case ROUTES_NAME.GROUP_BY_ID: {
      accessToGroupSelectActions.value = [];
      groupAutoSelectMode.value = GroupAutoSelectModeEnum.ByRouteId;
      break;
    }
    case ROUTES_NAME.GROUP_DASHBOARD: {
      accessToGroupSelectActions.value = [
        GroupSelectAccessEnum.RemoveGroup,
        GroupSelectAccessEnum.RepickGroup,
        GroupSelectAccessEnum.PickAllNetwork,
        GroupSelectAccessEnum.RepickProject,
      ];
      groupAutoSelectMode.value = GroupAutoSelectModeEnum.ByRouteId;
      break;
    }
    default: {
      accessToGroupSelectActions.value = [
        GroupSelectAccessEnum.RemoveGroup,
        GroupSelectAccessEnum.RepickGroup,
        GroupSelectAccessEnum.PickAllNetwork,
        GroupSelectAccessEnum.RepickProject,
      ];
      groupAutoSelectMode.value = GroupAutoSelectModeEnum.FirstAvailableGroup;
    }
  }
});

onUnmounted(() => {
  files.value = [];
});
//#endregion
</script>

<style scoped lang="scss">
ion-toolbar {
  --padding-bottom: 4px;
  --padding-top: 4px;
  --padding-end: 4px;
  --padding-start: 4px;
  height: auto;
  border-radius: app-radius(md);
  --background: var(--ion-color-light-background);
}
ion-toolbar .toolbar-container {
  display: flex;
  align-items: end;
}
ion-toolbar.task-toolbar {
  --background: var(--ion-color-light);
  --min-height: unset;
  --padding-top: 4px;
  --padding-bottom: 4px;
  border-radius: app-radius(sm);
}
ion-toolbar.task-toolbar .attach-btn {
  --background: var(--ion-color-light);
  --padding-start: unset;
}
ion-toolbar.task-toolbar .send-btn {
  --min-height: unset;
}
ion-toolbar.task-toolbar ion-textarea {
  --padding-bottom: unset;
  margin-bottom: 6px;
}

ion-toolbar .toolbar-container ion-item {
  --background: var(--ion-color-light-background);
}

ion-toolbar .toolbar-container ion-item ion-icon {
  @include respond-to-max-width(sm) {
    width: 3rem;
  }
}

ion-textarea {
  margin-top: 0;
  max-height: 14rem;
  margin-bottom: 4px;
  --padding-start: 4px;
  align-self: auto;
  overflow: auto;
  --padding-top: #{app-padding(lg)};
}
.files-list {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.file-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.file-wrapper file-info {
  justify-content: start;
  align-items: start;
}
.file-wrapper img {
  max-height: 80px;
  max-width: 80px;
}
.files-list ion-icon {
  color: var(--ion-color-intra);
}
.loader {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.post_create-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .drag {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
  }
}
.post_create-controls .send {
  display: flex;
  justify-content: end;
  flex-grow: 1;
  overflow: hidden;

  @include respond-to-max-width(2xs) {
    justify-content: unset;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
  }
}
.post_create-controls .attach {
  margin-right: app-padding(md);
}
.behalf-select-button {
  height: 100%;
}

.group-select-button {
  flex-shrink: 1;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
}

.schedule-post-button {
  flex-shrink: 1;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 0.5rem;
}
.schedule-post-button:has(.delete-button) {
  margin-left: 0;
}

.preview-post-button {
  margin-left: 0.5rem;
  min-width: 116px;
}
.preview-post-button ion-button {
  min-height: 40px;
  --background: transparent !important;
  --border-color: var(--ion-color-custom-element-lighter);
  width: 100%;
  min-width: 0;
  --background-hover: var(--ion-color-custom-element-lighter);
}
.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  white-space: nowrap !important;
}

.post_create-controls .submit {
  margin-left: 0.5rem;
}
.post_create-controls ion-button {
  --background: var(--ion-color-light-background-contrast-custom);
  --color: var(--ion-color-custom-gray-darker);
  --box-shadow: none;
  margin: 0;
  --border-radius: #{app-radius(md)};
  min-height: calc(app-padding(md) * 5);
}
.post_create-controls ion-button.delete {
  --color: var(--ion-color-custom);
  --background: var(--ion-color-danger);
  --border-radius: #{app-radius(md)};
}
</style>

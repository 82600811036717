<template>
  <ion-item
    v-if="isFloating"
    mode="ios"
    lines="none"
    :class="{
      'is-floating': isFloating,
      'single-item': isSingleItem,
      'no-border': noBorder,
      'no-padding': noPadding,
      wide: wide,
    }"
  >
    <ion-input
      ref="input"
      :autocapitalize="autocapitalize"
      :autocomplete="autocomplete"
      :required="required"
      :spellcheck="spellcheck"
      :type="type"
      :value="value"
      :maxlength="maxlength"
      :placeholder="placeholder"
      label-placement="floating"
      :class="[`ta-${textAlign}`]"
      @ion-input="handleInputChange"
      @ion-focus="emit('onFocus')"
      @ion-blur="emit('onBlur')"
      @keyup.enter="keyUp"
      @paste="handlePaste"
    >
      <div slot="label">
        {{ placeholder }}
        <ion-text v-if="showRequired && required && !value" color="danger">
          <!--eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <span>{{ `*` }}</span>
        </ion-text>
      </div>
    </ion-input>
  </ion-item>

  <ion-item
    v-else
    lines="none"
    :class="[
      {
        'single-item': isSingleItem,
        'on-behalf-custom': onBehalfCustom,
        'search-custom': searchCustom,
      },
      customSize,
    ]"
  >
    <ion-input
      ref="input"
      :class="[customSize, `ta-${textAlign}`]"
      :placeholder="placeholder"
      :autocapitalize="autocapitalize"
      :autocomplete="autocomplete"
      :required="required"
      :spellcheck="spellcheck"
      :type="type"
      :value="value"
      :maxlength="maxlength"
      label-placement="stacked"
      :label="labelFlag ? placeholder : undefined"
      @ion-input="handleInputChange"
      @keydown.enter="keyUp"
      @paste="handlePaste"
    />
    <slot />
  </ion-item>
</template>

<script lang="ts" setup>
import type { AutocompleteTypes, TextFieldTypes } from '@ionic/core';
import { IonInput, IonItem, IonText } from '@ionic/vue';
import type { PropType } from 'vue';
import { onMounted, ref } from 'vue';
import { keyUpSend } from '@/helpers';

// Props
const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  autocapitalize: {
    type: String,
    default: '',
  },
  autocomplete: {
    type: String as PropType<AutocompleteTypes>,
    default: 'off',
  },
  required: {
    type: Boolean,
    default: false,
  },
  showRequired: {
    type: Boolean,
    default: false,
  },
  spellcheck: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<TextFieldTypes>,
    default: 'text',
  },
  value: {
    type: [String, Number],
    default: '',
  },
  labelFlag: {
    type: Boolean,
    default: false,
  },
  isFloating: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: undefined,
  },
  customSize: {
    type: String,
    default: '',
  },
  isSingleItem: {
    type: Boolean,
    default: false,
  },
  applyAutoFocus: {
    type: Boolean,
    default: false,
  },
  noBorder: {
    type: Boolean,
    default: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  wide: {
    type: Boolean,
    default: false,
  },
  onBehalfCustom: {
    type: Boolean,
    default: false,
  },
  searchCustom: {
    type: Boolean,
    default: false,
  },
  textAlign: {
    type: String as PropType<'start' | 'end'>,
    default: 'start',
  },
});

// Refs
const input = ref<any>(null);
const keyUpAction = ref<boolean>(false);

// Methods
const handleInputChange = (event: Event) => {
  let value = '';
  value = (event.target as HTMLInputElement).value;

  if (!props.maxlength) {
    emit('update:value', value);
  } else {
    if (props.maxlength >= value.length) {
      emit('update:value', value);
    }
  }
};

const handlePaste = (event: ClipboardEvent) => {
  const clipboardData = event.clipboardData;
  if (!clipboardData) return;

  if (clipboardData.files.length > 0) {
    event.preventDefault();

    const files = clipboardData.files;
    emit('onPasteFiles', files);
    return;
  }
};

const keyUp = (event: KeyboardEvent | MouseEvent) => {
  keyUpSend(event) ? (keyUpAction.value = true) : (keyUpAction.value = false);
  emit('update:key-up', keyUpAction.value);
};

// Lifecycle
onMounted(async () => {
  if (input.value && props.applyAutoFocus) {
    setTimeout(() => input.value.$el.setFocus(), 0);
  }
});

// Emits
const emit = defineEmits(['update:value', 'update:key-up', 'onFocus', 'onBlur', 'onPasteFiles']);
</script>

<style scoped lang="scss">
ion-item {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --inner-padding-end: 0;
  --background: transparent;
  margin-top: 1rem;
  border: 1px solid var(--ion-color-medium);
  border-radius: app-radius(md);

  &.is-floating {
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid var(--ion-color-medium);
    min-height: 0 !important;
  }

  &.is-floating::part(native) {
    border-radius: unset;
    border-radius: app-radius(md) app-radius(md) 0 0;
  }

  &.on-behalf-custom {
    border: 2px solid var(--ion-color-custom-element-lighter);
    border-radius: #{app-radius(md)};
    --min-height: 37px !important;
    --min-width: 85px !important;

    ion-input.input-label-placement-stacked.sc-ion-input-md-h {
      min-height: 37px !important;
      min-width: 85px !important;
    }
  }
  &.on-behalf-custom::part(native) {
    height: 37px;
  }

  &.search-custom {
    border-radius: #{app-radius(md)};
    border: none;
    --min-height: 40px !important;

    ion-input.input-label-placement-stacked.sc-ion-input-md-h,
    .input-label-placement-stacked.sc-ion-input-ios-h {
      border-radius: #{app-radius(md)};
      min-height: 40px !important;
    }
    ion-input {
      --background: var(--ion-color-light-background-contrast);
    }
  }
  &.search-custom::part(native) {
    --background: var(--ion-color-light-background-contrast);
  }

  &.no-border {
    border-bottom: none;
  }

  &.medium {
    --min-height: 38px;
  }

  &.single-item {
    margin-top: 0;
  }

  &.wide {
    min-width: 80vw;
  }
}
ion-item::part(native) {
  border-radius: app-radius(md);

  &.no-padding {
    padding: 0;
    padding-left: 2px;
  }
}
ion-input {
  font-size: 1.1rem;
  caret-color: auto;
  --min-height: 4rem;
  --color: var(--ion-color-dark);
  // --placeholder-color: var(--ion-color-dark);
  --placeholder-color: var(--ion-color-medium);
  --background: transparent;

  &.medium {
    font-size: 0.9rem;
  }
}
</style>

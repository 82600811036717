import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { openBadgePickerModal, openGroupManageModal, openPostCreateMobileModal } from './modalComponents';
import { openAppBarFilterPopover, openAppBarSortingPopover } from './popoverComponents';

import {
  DocBrowserEntityEnum,
  DocBrowserModeEnum,
  EventBusEnum,
  TaskManagementMainHeaderMenuEnum,
  TaskManagementMainHeaderSearchModesEnum,
  TaskManagementMilestonesSortByEnum,
  TaskManagementProjectsSortByEnum,
  TaskManagementTaskModalMenuItemActionEnum,
  TaskManagementTasksSortByEnum,
  TaskManagementTasksStatusFilterEnum,
} from '@/enums';
import {
  AppBarEnum,
  AppMenuEnum,
  DataViewMode,
  GroupsFilterEnum,
  PostTypeActionEnum,
  TaskManagementSortDirectionEnum,
  TaskManagementTasksPageTypeEnum,
  TopicsFilterEnum,
  TopicsSortTypeEnum,
  UIKitFilterEnum,
  UserRoleEnum,
  UsersFilterEnum,
  AppIconsEnum,
  SortingTypeEnum,
  SortingDirectionEnum,
} from '@/enums';
import { useGroups, useTaskManagement, useDocs, useTopics, useToasts } from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { useAppStore, useNetworkStore, useProjectsStore, useUserStore, useTopicStore, useMenuStore } from '@/store';
import type { AppBarFilterType, AppBarItem } from '@/types';

import type { MenuItemModel } from '@/types';
import { useEventBus } from '@vueuse/core';

export type FilterType = {
  title: string;
  value: AppBarFilterType;
  enabled: boolean;
};

export type IUseAppBar = {
  /**
   * Returns the elements of the bar
   *
   * @param docBrowserEntity - Use it if it is an app-bar from the dock-browser
   * @param docBrowserMode - Use it if it is an app-bar from the dock-browser
   */
  getBarItems(
    typeFlag: AppMenuEnum,
    isMobile: boolean,
    docBrowserEntity?: DocBrowserEntityEnum,
    docBrowserMode?: DocBrowserModeEnum
  ): AppBarItem[];
  /**
   * Returns the name of the filter
   */
  getFilterLabel(
    label: GroupsFilterEnum | UsersFilterEnum | TaskManagementTasksPageTypeEnum | TopicsFilterEnum
  ): string;
  /**
   * Returns the selected filter
   */
  /**
   * Returns the variants when a filter is selected
   */
  getFilterTypes(typeFlag: AppMenuEnum, withSearch: boolean): FilterType[] | [];
  /**
   * Returns the variants when a sorting is selected
   */
  getSortingTypes(
    typeFlag: AppMenuEnum
  ): MenuItemModel<
    | TaskManagementTaskModalMenuItemActionEnum
    | TaskManagementMainHeaderMenuEnum
    | TaskManagementMainHeaderSearchModesEnum
    | TaskManagementTasksSortByEnum
    | TaskManagementMilestonesSortByEnum
    | TaskManagementProjectsSortByEnum
    | TaskManagementTasksStatusFilterEnum
    | TopicsSortTypeEnum
  >[];
  /**
   * Returns the selected filter
   */
  getSelectedFilter(typeFlag: AppMenuEnum): AppBarFilterType;
  /**
   * Returns the selected sorting
   */
  getSelectedSortingType(
    typeFlag: AppMenuEnum
  ):
    | TaskManagementTasksSortByEnum
    | TaskManagementMilestonesSortByEnum
    | TaskManagementProjectsSortByEnum
    | SortingTypeEnum
    | TopicsSortTypeEnum;
  /**
   * Returns the selected sorting direction
   */
  getSelectedSortingDirection(typeFlag: AppMenuEnum): TaskManagementSortDirectionEnum | SortingDirectionEnum;

  /**
   * Method for changing the filter
   */
  changeFilter(typeFlag: AppMenuEnum, ev: Event): Promise<AppBarFilterType | undefined>;
  /**
   * Method for changing the sorting
   */
  changeSorting(typeFlag: AppMenuEnum, ev: Event): Promise<boolean>;
  /**
   * Method for changing the content display mod
   */
  changeViewMode(typeFlag: AppMenuEnum): void;
  /**
   * Method for changing the sorting direction
   */
  changeSortingDirection(typeFlag: AppMenuEnum): void;

  /**
   * Returns the selected content display mod
   */
  selectedViewMode(typeFlag: AppMenuEnum): DataViewMode;
  /**
   * Actions when the "Create" button is pressed
   */
  createAction(ev: Event, typeFlag: AppMenuEnum): Promise<void>;
  /**
   * Method for resetting all filters
   */
  resetAllFilters(): void;
};

export const useAppBar = (): IUseAppBar => {
  //#region Variables
  const { showSonnerToast } = useToasts();
  const { t } = useI18n();

  const appStore = useAppStore();
  const projectsStore = useProjectsStore();
  const topicStore = useTopicStore();

  const networkStore = useNetworkStore();
  const userStore = useUserStore();
  const menuStore = useMenuStore();
  //#endregion

  //#region Private methods
  const _getGroupsPageItems = (isMobile: boolean): AppBarItem[] => {
    const currentUserRoleId: ComputedRef<number> = computed(() => userStore.current?.roleId ?? 0);
    const accessToCreateGroup = (): boolean =>
      networkStore.settings?.everyoneCanCreateGroups || currentUserRoleId.value >= UserRoleEnum.Moderator;

    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: isMobile,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: false,
      },
      {
        title: t('viewType'),
        subTitle: '',
        value: AppBarEnum.ViewMode,
        icon: selectedViewMode(AppMenuEnum.Groups) === DataViewMode.Grid ? AppIconsEnum.Table2 : AppIconsEnum.List,
        disabled: isMobile,
      },
      {
        title: t('filter'),
        subTitle: getFilterLabel(getSelectedFilter(AppMenuEnum.Groups)),
        value: AppBarEnum.FilterType,
        icon: AppIconsEnum.Filter,
        disabled: isMobile,
      },
      {
        title: t('groupPage.infoModal.createTitle'),
        subTitle: '',
        value: AppBarEnum.Create,
        icon: AppIconsEnum.Add,
        disabled: !accessToCreateGroup() || isMobile,
      },
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: !isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const _getUsersPageItems = (isMobile: boolean): AppBarItem[] => {
    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: isMobile,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: false,
      },
      {
        title: t('viewType'),
        subTitle: '',
        value: AppBarEnum.ViewMode,
        icon: selectedViewMode(AppMenuEnum.People) === DataViewMode.Grid ? AppIconsEnum.Table2 : AppIconsEnum.List,
        disabled: isMobile,
      },
      {
        title: t('filter'),
        subTitle: getFilterLabel(getSelectedFilter(AppMenuEnum.People)),
        value: AppBarEnum.FilterType,
        icon: AppIconsEnum.Filter,
        disabled: isMobile,
      },
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: !isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const _getTasksPageItems = (isMobile: boolean): AppBarItem[] => {
    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: isMobile,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: false,
      },
      {
        title: t('filter'),
        subTitle: getFilterLabel(getSelectedFilter(AppMenuEnum.Tasks)),
        value: AppBarEnum.FilterType,
        icon: AppIconsEnum.Filter,
        disabled: isMobile,
      },
      {
        title: t('sorting.title'),
        subTitle: useTaskManagement().getSortTitle(
          getSelectedSortingType(AppMenuEnum.Tasks) as
            | TaskManagementTasksSortByEnum
            | TaskManagementMilestonesSortByEnum
            | TaskManagementProjectsSortByEnum
        ),
        value: AppBarEnum.SortingType,
        icon: AppIconsEnum.AlphaOrderAz,
        disabled: isMobile,
      },
      {
        title: t('sorting.direction.title'),
        subTitle:
          useTaskManagement().getSelectedSortDirection() === TaskManagementSortDirectionEnum.Asc
            ? t('sorting.direction.asc')
            : t('sorting.direction.desc'),
        value: AppBarEnum.SortingDirection,
        icon:
          useTaskManagement().getSelectedSortDirection() === TaskManagementSortDirectionEnum.Asc
            ? AppIconsEnum.ArrowUp
            : AppIconsEnum.ArrowDown,
        disabled: isMobile,
      },
      {
        title: t('taskManagement.tasks.create'),
        subTitle: '',
        value: AppBarEnum.Create,
        icon: AppIconsEnum.Add,
        disabled: !useTaskManagement().getAccessToCreateTask() || isMobile,
      },
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: !isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const _getProjectsPageItems = (isMobile: boolean): AppBarItem[] => {
    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: isMobile,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: false,
      },
      {
        title: t('sorting.title'),
        subTitle: useTaskManagement().getSortTitle(
          getSelectedSortingType(AppMenuEnum.Projects) as
            | TaskManagementTasksSortByEnum
            | TaskManagementMilestonesSortByEnum
            | TaskManagementProjectsSortByEnum
        ),
        value: AppBarEnum.SortingType,
        icon: AppIconsEnum.AlphaOrderAz,
        disabled: isMobile,
      },
      {
        title: t('sorting.direction.title'),
        subTitle:
          useTaskManagement().getSelectedSortDirection() === TaskManagementSortDirectionEnum.Asc
            ? t('sorting.direction.asc')
            : t('sorting.direction.desc'),
        value: AppBarEnum.SortingDirection,
        icon:
          useTaskManagement().getSelectedSortDirection() === TaskManagementSortDirectionEnum.Asc
            ? AppIconsEnum.ArrowUp
            : AppIconsEnum.ArrowDown,
        disabled: isMobile,
      },
      {
        title: t('taskManagement.tasks.create'),
        subTitle: '',
        value: AppBarEnum.Create,
        icon: AppIconsEnum.Add,
        disabled: !useTaskManagement().getAccessToCreateTask() || isMobile,
      },
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: !isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const _getTopicsPageItems = (isMobile: boolean): AppBarItem[] => {
    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: isMobile,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: false,
      },
      {
        title: t('viewType'),
        subTitle: '',
        value: AppBarEnum.ViewMode,
        icon: selectedViewMode(AppMenuEnum.Topics) === DataViewMode.Grid ? AppIconsEnum.Table2 : AppIconsEnum.List,
        disabled: isMobile,
      },
      {
        value: AppBarEnum.SortingType,
        title: t('sorting.title'),
        subTitle:
          getSelectedSortingType(AppMenuEnum.Topics) === TopicsSortTypeEnum.ByTitle
            ? t('sorting.byTitle')
            : t('sorting.byPopularity'),
        icon: AppIconsEnum.AlphaOrderAz,
        disabled: isMobile,
      },
      {
        title: t('filter'),
        subTitle: getFilterLabel(getSelectedFilter(AppMenuEnum.Topics)),
        value: AppBarEnum.FilterType,
        icon: AppIconsEnum.Filter,
        disabled: isMobile,
      },
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: !isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const _getNetworksPageItems = (isMobile: boolean): AppBarItem[] => {
    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: isMobile,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: false,
      },
      {
        title: t('viewType'),
        subTitle: '',
        value: AppBarEnum.ViewMode,
        icon: selectedViewMode(AppMenuEnum.Networks) === DataViewMode.Grid ? AppIconsEnum.Table2 : AppIconsEnum.List,
        disabled: isMobile,
      },
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: !isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const _getPagesPageItems = (isMobile: boolean): AppBarItem[] => {
    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: isMobile,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: false,
      },
      /* {
        title: t('viewType'),
        subTitle: '',
        value: AppBarEnum.ViewMode,
        icon:
          selectedViewMode(AppMenuEnum.Topics) === DataViewMode.Grid
            ? AppIconsEnum.Table2'
            : AppIconsEnum.List,
        disabled: isMobile,
      }, */
      /* {
        title: t('filter'),
        subTitle: getFilterLabel(getSelectedFilter(AppMenuEnum.Topics)),
        value: AppBarEnum.FilterType,
        icon: AppIconsEnum.Filter,
        enabled: false,
      }, */
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: !isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const _getIconsPageItems = (isMobile: boolean): AppBarItem[] => {
    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: isMobile,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const _getLeftMenuItems = (): AppBarItem[] => {
    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: false,
      },
      {
        title: t('sorting.title'),
        subTitle: useGroups().getSortTitle(getSelectedSortingType(AppMenuEnum.LeftMenu) as SortingTypeEnum),
        value: AppBarEnum.SortingType,
        icon: AppIconsEnum.AlphaOrderAz,
        disabled: false,
      },
      {
        title: t('sorting.direction.title'),
        subTitle:
          menuStore.sortingDirection === SortingDirectionEnum.Asc
            ? t('sorting.direction.asc')
            : t('sorting.direction.desc'),
        value: AppBarEnum.SortingDirection,
        icon: menuStore.sortingDirection === SortingDirectionEnum.Asc ? AppIconsEnum.ArrowUp : AppIconsEnum.ArrowDown,
        disabled: false,
      },
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: false,
      },
    ].filter(({ disabled }) => !disabled);
  };
  //#endregion

  /**
   * --------------------------------------------------------------------------------
   * Getters
   * --------------------------------------------------------------------------------
   */
  const getFilterLabel = (label: AppBarFilterType): string => {
    const map: Partial<Record<AppBarFilterType, string>> = Object.freeze({
      [GroupsFilterEnum.All]: t('appLists.groupsPageTitle'),
      [UsersFilterEnum.UsersPage]: t('appLists.peoplePageTitle'),
      [GroupsFilterEnum.MyGroups]: t('subscribe.subscribedToGroups'),
      [GroupsFilterEnum.ByUser]: t('subscribe.subscribedToGroups'),
      [UsersFilterEnum.Following]: t('subscribe.subscribedToUsers'),
      [GroupsFilterEnum.Admin]: t('appLists.admin'),
      [GroupsFilterEnum.Suggestion]: t('appLists.suggestion'),
      [GroupsFilterEnum.Hidden]: t('appLists.hidden'),
      [UsersFilterEnum.Search]: t('search.searchResults'),
      [TaskManagementTasksPageTypeEnum.Author]: t('taskManagement.tasks.tasksAuthor'),
      [TaskManagementTasksPageTypeEnum.Assignee]: t('taskManagement.tasks.tasksAssignee'),
      [TopicsFilterEnum.Recommended]: t('topicsPage.recommended'),
      [TopicsFilterEnum.Popular]: t('topicsPage.popular'),
      [TopicsFilterEnum.Following]: t('subscribe.mySubscriptions'),
      [TopicsFilterEnum.Search]: t('topicsPage.search'),
      [UsersFilterEnum.ByBadge]:
        t('feed.badge.byBadge') + (appStore.usersFilter.subTitle ? ` (${appStore.usersFilter.subTitle})` : ''),
    });

    return map[label] ?? '';
  };

  const getBarItems = (
    typeFlag: AppMenuEnum,
    isMobile: boolean,
    docBrowserEntity?: DocBrowserEntityEnum,
    docBrowserMode?: DocBrowserModeEnum
  ): AppBarItem[] => {
    const useDocsHelper = useDocs();
    const map: Partial<Record<AppMenuEnum, AppBarItem[]>> = Object.freeze({
      [AppMenuEnum.Groups]: _getGroupsPageItems(isMobile),
      [AppMenuEnum.People]: _getUsersPageItems(isMobile),
      [AppMenuEnum.Tasks]: _getTasksPageItems(isMobile),
      [AppMenuEnum.Networks]: _getNetworksPageItems(isMobile),
      [AppMenuEnum.Pages]: _getPagesPageItems(isMobile),
      [AppMenuEnum.Icons]: _getIconsPageItems(isMobile),
      [AppMenuEnum.Topics]: _getTopicsPageItems(isMobile),
      [AppMenuEnum.Projects]: _getProjectsPageItems(isMobile),
      [AppMenuEnum.LeftMenu]: _getLeftMenuItems(),
      [AppMenuEnum.Docs]: useDocsHelper.getAppBarItems(
        isMobile,
        docBrowserEntity ?? DocBrowserEntityEnum.DocsPage,
        docBrowserMode ?? useDocsHelper.defaultMode
      ),
    });

    return map[typeFlag] ?? [];
  };

  const getFilterTypes = (typeFlag: AppMenuEnum, withSearch: boolean): FilterType[] => {
    const getGroupsFilterTypes = (): FilterType[] => {
      const currentUserRoleId: ComputedRef<UserRoleEnum> = computed(() => userStore.current?.roleId ?? 0);
      return [
        {
          title: getFilterLabel(GroupsFilterEnum.MyGroups),
          value: GroupsFilterEnum.MyGroups,
          enabled: true,
        },
        {
          title: getFilterLabel(GroupsFilterEnum.All),
          value: GroupsFilterEnum.All,
          enabled: true,
        },
        {
          title: getFilterLabel(GroupsFilterEnum.Admin),
          value: GroupsFilterEnum.Admin,
          enabled: true,
        },
        {
          title: getFilterLabel(GroupsFilterEnum.Suggestion),
          value: GroupsFilterEnum.Suggestion,
          enabled: true,
        },
        {
          title: getFilterLabel(GroupsFilterEnum.Hidden),
          value: GroupsFilterEnum.Hidden,
          enabled: currentUserRoleId.value >= UserRoleEnum.SuperAdministrator,
        },
      ].filter((n) => n.enabled);
    };

    const getPeopleFilterTypes = (): FilterType[] => {
      return [
        {
          title: getFilterLabel(UsersFilterEnum.UsersPage),
          value: UsersFilterEnum.UsersPage,
          enabled: true,
        },
        {
          title: getFilterLabel(UsersFilterEnum.Following),
          value: UsersFilterEnum.Following,
          enabled: true,
        },
        {
          title: getFilterLabel(UsersFilterEnum.ByBadge),
          value: UsersFilterEnum.ByBadge,
          enabled: true,
        },
        {
          title: getFilterLabel(UsersFilterEnum.Search),
          value: UsersFilterEnum.Search,
          enabled: withSearch,
        },
      ].filter((n) => n.enabled);
    };

    const getTasksFilterTypes = (): FilterType[] => {
      return [
        {
          title: getFilterLabel(TaskManagementTasksPageTypeEnum.Assignee),
          value: TaskManagementTasksPageTypeEnum.Assignee,
          enabled: true,
        },
        {
          title: getFilterLabel(TaskManagementTasksPageTypeEnum.Author),
          value: TaskManagementTasksPageTypeEnum.Author,
          enabled: true,
        },
      ];
    };

    const getTopicsFilterTypes = (): FilterType[] => {
      return [
        {
          title: getFilterLabel(TopicsFilterEnum.Popular),
          value: TopicsFilterEnum.Popular,
          enabled: true,
        },
        {
          title: getFilterLabel(TopicsFilterEnum.Recommended),
          value: TopicsFilterEnum.Recommended,
          enabled: true,
        },
        {
          title: getFilterLabel(TopicsFilterEnum.Following),
          value: TopicsFilterEnum.Following,
          enabled: true,
        },
      ];
    };

    const getIconsFilterTypes = (): FilterType[] => {
      return [
        {
          title: UIKitFilterEnum.Intra,
          value: UIKitFilterEnum.Intra,
          enabled: true,
        },
        {
          title: UIKitFilterEnum.Gitlab,
          value: UIKitFilterEnum.Gitlab,
          enabled: true,
        },
      ].filter((n) => n.enabled);
    };

    const map: Partial<Record<AppMenuEnum, FilterType[] | []>> = Object.freeze({
      [AppMenuEnum.Groups]: getGroupsFilterTypes(),
      [AppMenuEnum.People]: getPeopleFilterTypes(),
      [AppMenuEnum.Tasks]: getTasksFilterTypes(),
      [AppMenuEnum.Topics]: getTopicsFilterTypes(),
      [AppMenuEnum.Icons]: getIconsFilterTypes(),
      [AppMenuEnum.Docs]: useDocs().getModeTypes(),
    });

    return map[typeFlag] ?? [];
  };

  const getSelectedSortingType = (
    typeFlag: AppMenuEnum
  ):
    | TaskManagementTasksSortByEnum
    | TaskManagementMilestonesSortByEnum
    | TaskManagementProjectsSortByEnum
    | SortingTypeEnum
    | TopicsSortTypeEnum => {
    const map: Partial<
      Record<
        AppMenuEnum,
        | TaskManagementTasksSortByEnum
        | TaskManagementMilestonesSortByEnum
        | TaskManagementProjectsSortByEnum
        | SortingTypeEnum
        | TopicsSortTypeEnum
      >
    > = Object.freeze({
      [AppMenuEnum.Tasks]: useTaskManagement().getSelectedSort(),
      [AppMenuEnum.Projects]: useTaskManagement().getSelectedSort(),
      [AppMenuEnum.LeftMenu]: menuStore.sortingType,
      [AppMenuEnum.Topics]: topicStore.topicsSort,
    });

    return map[typeFlag] ?? SortingTypeEnum.Name;
  };

  const getSelectedSortingDirection = (
    typeFlag: AppMenuEnum
  ): TaskManagementSortDirectionEnum | SortingDirectionEnum => {
    const map: Partial<Record<AppMenuEnum, TaskManagementSortDirectionEnum | SortingDirectionEnum>> = Object.freeze({
      [AppMenuEnum.Tasks]: useTaskManagement().getSelectedSortDirection(),
      [AppMenuEnum.Projects]: useTaskManagement().getSelectedSortDirection(),
      [AppMenuEnum.LeftMenu]: menuStore.sortingDirection,
    });

    return map[typeFlag] ?? SortingDirectionEnum.Desc;
  };

  const getSelectedFilter = (typeFlag: AppMenuEnum): AppBarFilterType => {
    const map: Partial<Record<AppMenuEnum, AppBarFilterType | undefined>> = {
      [AppMenuEnum.Groups]: appStore.groupsFilter,
      [AppMenuEnum.People]: appStore.usersFilter.filter,
      [AppMenuEnum.Tasks]: projectsStore.tasksListType,
      [AppMenuEnum.Topics]: topicStore.topicsFilter,
      [AppMenuEnum.Icons]: appStore.UIKitFilter,
      [AppMenuEnum.Docs]: undefined,
    };

    return map[typeFlag] ?? appStore.usersFilter.filter;
  };

  const getSortingTypes = (
    typeFlag: AppMenuEnum
  ): MenuItemModel<
    | TaskManagementTaskModalMenuItemActionEnum
    | TaskManagementMainHeaderMenuEnum
    | TaskManagementMainHeaderSearchModesEnum
    | TaskManagementTasksSortByEnum
    | TaskManagementMilestonesSortByEnum
    | TaskManagementProjectsSortByEnum
    | TaskManagementTasksStatusFilterEnum
    | TopicsSortTypeEnum
  >[] => {
    switch (typeFlag) {
      case AppMenuEnum.Tasks: {
        return useTaskManagement()
          .getTasksSortingModes()
          .filter(({ disabled }) => !disabled);
      }

      case AppMenuEnum.Projects: {
        return useTaskManagement()
          .getProjectsSortingModes()
          .filter(({ disabled }) => !disabled);
      }

      case AppMenuEnum.Topics: {
        return useTopics()
          .getTopicsSortingModes()
          .filter(({ disabled }) => !disabled);
      }

      default:
        return [];
    }
  };

  /**
   * --------------------------------------------------------------------------------
   * Changers
   * --------------------------------------------------------------------------------
   */
  const changeFilter = async (typeFlag: AppMenuEnum, ev: Event): Promise<AppBarFilterType | undefined> => {
    let result: AppBarFilterType | undefined = undefined;

    result = (await openAppBarFilterPopover(ev, typeFlag)).data;

    const _changePeopleFilter = async (): Promise<void> => {
      if (result === UsersFilterEnum.ByBadge) {
        const badge = await openBadgePickerModal();
        if (badge) {
          appStore.setUsersFilter(UsersFilterEnum.ByBadge, badge?.id ?? null, badge?.title ?? '');
        }
      } else if (result === UsersFilterEnum.UsersPage || result === UsersFilterEnum.Following) {
        appStore.setUsersFilter(result, null, '');
      }
    };

    if (result) {
      const patchMap: Record<AppMenuEnum, () => Promise<void>> = {
        [AppMenuEnum.Groups]: async () => appStore.$patch({ groupsFilter: result as GroupsFilterEnum }),
        [AppMenuEnum.People]: async () => await _changePeopleFilter(),
        [AppMenuEnum.Tasks]: async () =>
          projectsStore.$patch({ tasksListType: result as TaskManagementTasksPageTypeEnum }),
        [AppMenuEnum.Topics]: async () => topicStore.$patch({ topicsFilter: result as TopicsFilterEnum }),
        [AppMenuEnum.Docs]: async () => {
          const bus = useEventBus<string>(EventBusEnum.DocBrowserSetMode);
          bus.emit(result as DocBrowserModeEnum);
          bus.reset();
        },
        [AppMenuEnum.Projects]: async () => undefined,
        [AppMenuEnum.Feed]: async () => undefined,
        [AppMenuEnum.Notifications]: async () => undefined,
        [AppMenuEnum.Messenger]: async () => undefined,
        [AppMenuEnum.Pages]: async () => undefined,
        [AppMenuEnum.HomePage]: async () => undefined,
        [AppMenuEnum.Calendar]: async () => undefined,
        [AppMenuEnum.Search]: async () => undefined,
        [AppMenuEnum.UsageRules]: async () => undefined,
        [AppMenuEnum.ProjectsStatistics]: async () => undefined,
        [AppMenuEnum.Milestones]: async () => undefined,
        [AppMenuEnum.AiAssistant]: async () => undefined,
        [AppMenuEnum.Profile]: async () => undefined,
        [AppMenuEnum.Ideas]: async () => undefined,
        [AppMenuEnum.More]: async () => undefined,
        [AppMenuEnum.Login]: async () => undefined,
        [AppMenuEnum.Networks]: async () => undefined,
        [AppMenuEnum.Settings]: async () => undefined,
        [AppMenuEnum.Wiki]: async () => undefined,
        [AppMenuEnum.CustomLink]: async () => undefined,
        [AppMenuEnum.SecondCustomLink]: async () => undefined,
        [AppMenuEnum.CustomPage]: async () => undefined,
        [AppMenuEnum.File]: async () => undefined,
        [AppMenuEnum.Plus]: async () => undefined,
        [AppMenuEnum.Campus]: async () => undefined,
        [AppMenuEnum.Admin]: async () => undefined,
        [AppMenuEnum.AdminDesign]: async () => undefined,
        [AppMenuEnum.AdminEmailFooter]: async () => undefined,
        [AppMenuEnum.AdminNetworkSettings]: async () => undefined,
        [AppMenuEnum.AdminNetworkDomainList]: async () => undefined,
        [AppMenuEnum.AdminBranding]: async () => undefined,
        [AppMenuEnum.AdminMobileApps]: async () => undefined,
        [AppMenuEnum.AdminUsageRules]: async () => undefined,
        [AppMenuEnum.AdminPasswordSettings]: async () => undefined,
        [AppMenuEnum.AdminApplications]: async () => undefined,
        [AppMenuEnum.AdminStatistics]: async () => undefined,
        [AppMenuEnum.AdminBanner]: async () => undefined,
        [AppMenuEnum.AdminTags]: async () => undefined,
        [AppMenuEnum.AdminUserManagement]: async () => undefined,
        [AppMenuEnum.AdminRestorePost]: async () => undefined,
        [AppMenuEnum.AdminBadges]: async () => undefined,
        [AppMenuEnum.Icons]: async () => undefined,
        [AppMenuEnum.LeftMenu]: async () => undefined,
      };

      if (patchMap[typeFlag]) {
        await patchMap[typeFlag]();
      }
    }
    return result;
  };

  const changeSorting = async (typeFlag: AppMenuEnum, ev: Event): Promise<boolean> => {
    const changeSortingTasks = (result: TaskManagementTasksSortByEnum): void => {
      projectsStore.$patch((state) => {
        state.sorting.tasks.sortBy = result as TaskManagementTasksSortByEnum;
      });
    };
    const changeSortingProjects = (result: TaskManagementProjectsSortByEnum): void => {
      projectsStore.$patch((state) => {
        state.sorting.projects.sortBy = result as TaskManagementProjectsSortByEnum;
      });
    };
    const changeSortingTopics = (result: TopicsSortTypeEnum): void => {
      topicStore.$patch({
        topicsSort: result as TopicsSortTypeEnum,
      });
    };

    const result = await openAppBarSortingPopover(ev, typeFlag);
    if (!result) return false;

    const map: Partial<Record<AppMenuEnum, () => void>> = Object.freeze({
      [AppMenuEnum.Tasks]: () => changeSortingTasks(result as TaskManagementTasksSortByEnum),
      [AppMenuEnum.Projects]: () => changeSortingProjects(result as TaskManagementProjectsSortByEnum),
      [AppMenuEnum.Topics]: () => changeSortingTopics(result as TopicsSortTypeEnum),
    });

    const handler = map[typeFlag];
    if (!handler) {
      console.warn('[WARN] No handler found for sorting:', typeFlag);
      return true;
    }

    try {
      handler();
    } catch (error) {
      console.error(`[ERROR] Error of sorting execution ${typeFlag}:`, error);
      showSonnerToast(t('errorResponse'), false);
    }

    return true;
  };

  const changeViewMode = (typeFlag: AppMenuEnum): void => {
    const _getViewMode = (): DataViewMode =>
      selectedViewMode(typeFlag) === DataViewMode.Grid ? DataViewMode.List : DataViewMode.Grid;

    const viewModeMap: Partial<Record<AppMenuEnum, () => void>> = {
      [AppMenuEnum.Groups]: () =>
        appStore.$patch({
          groupsViewMode: _getViewMode(),
        }),
      [AppMenuEnum.People]: () =>
        appStore.$patch({
          usersViewMode: _getViewMode(),
        }),
      [AppMenuEnum.Topics]: () =>
        topicStore.$patch({
          topicsView: _getViewMode(),
        }),
      [AppMenuEnum.Networks]: () =>
        appStore.$patch({
          networksViewMode: _getViewMode(),
        }),
      [AppMenuEnum.Docs]: () =>
        appStore.$patch({
          docsViewMode: selectedViewMode(typeFlag) === DataViewMode.Table ? DataViewMode.Grid : DataViewMode.Table,
        }),
    };

    viewModeMap[typeFlag]?.();
  };

  const changeSortingDirection = (typeFlag: AppMenuEnum): void => {
    const map: Partial<Record<AppMenuEnum, () => void>> = Object.freeze({
      [AppMenuEnum.Tasks]: useTaskManagement().setSortDirection,
      [AppMenuEnum.Projects]: useTaskManagement().setSortDirection,
      [AppMenuEnum.LeftMenu]: menuStore.toggleSortingDirection,
    });

    const handler = map[typeFlag];
    if (!handler) {
      console.warn('[WARN] No handler found for sorting direction:', typeFlag);
      return;
    }

    try {
      handler();
    } catch (error) {
      console.error(`[ERROR] Error changing the sorting direction ${typeFlag}:`, error);
      showSonnerToast(t('errorResponse'), false);
    }
  };

  /**
   * --------------------------------------------------------------------------------
   * Other
   * --------------------------------------------------------------------------------
   */

  const selectedViewMode = (typeFlag: AppMenuEnum): DataViewMode => {
    const viewModeMap: Partial<Record<AppMenuEnum, DataViewMode>> = {
      [AppMenuEnum.Groups]: appStore.groupsViewMode,
      [AppMenuEnum.People]: appStore.usersViewMode,
      [AppMenuEnum.Topics]: topicStore.topicsView,
      [AppMenuEnum.Networks]: appStore.networksViewMode,
      [AppMenuEnum.Pages]: appStore.pagesViewMode,
      [AppMenuEnum.Docs]: appStore.docsViewMode,
    };

    return viewModeMap[typeFlag] ?? DataViewMode.List;
  };

  const createAction = async (ev: Event, typeFlag: AppMenuEnum): Promise<void> => {
    switch (typeFlag) {
      case AppMenuEnum.Groups: {
        return await openGroupManageModal();
      }

      case AppMenuEnum.Tasks: {
        return await openPostCreateMobileModal(PostTypeActionEnum.Task);
      }

      case AppMenuEnum.Projects: {
        return await useTaskManagement().taskManagementProjectSwitchHelper(
          ev,
          false,
          false,
          true,
          router.currentRoute.value.name === ROUTES_NAME.GROUP_BY_ID
            ? Number(router.currentRoute.value.params.id)
            : undefined
        );
      }
    }
  };

  const resetAllFilters = (): void => {
    appStore.$patch((state) => {
      state.groupsFilter = GroupsFilterEnum.Suggestion;
      state.usersFilter = { filter: UsersFilterEnum.UsersPage, id: null, subTitle: '' };
    });

    projectsStore.$patch((state) => {
      state.tasksListType = TaskManagementTasksPageTypeEnum.Assignee;
    });

    topicStore.$patch((state) => {
      state.topicsFilter = TopicsFilterEnum.Recommended;
    });
  };

  return {
    getBarItems,
    getFilterLabel,
    getFilterTypes,
    getSortingTypes,
    getSelectedFilter,
    getSelectedSortingType,
    getSelectedSortingDirection,

    changeFilter,
    changeSorting,
    changeViewMode,
    changeSortingDirection,

    selectedViewMode,
    createAction,
    resetAllFilters,
  };
};
